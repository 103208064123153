import React, { useContext, useEffect } from "react";
import Button from "../../../../shared/button/button";
import { MEDIUM } from "../../../../shared/buttonSize";
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../shared/buttonStyles";
import InputDropdown from "../../../../shared/input-dropdown/inputDropdown";
import Styles from "./addNewUserModal.module.scss";
import sharedStyle from "../../../../shared/sharedStyle.module.scss";
import popUpStyles from "../../../../shared/popup.module.scss";
import Input from "../../../../shared/input/input";
import ErrorMessage from "../../../../shared/error-message/errorMessage";
import Checkbox from "../../../../shared/checkbox/checkbox";
import {
  checkIsEmpty,
  isValidEmailFirstChar,
  isValidName,
  isValidPhoneNumber,
} from "../../../../utils/validation";
import { callGetApi, callPostApi } from "../../../../api/axios";
import { ERROR } from "../../../../utils/toastType";
import { ToastContext } from "../../../../context/toastContext";
import { ApmContext } from "../../../../utils/apmProvider";

function AddNewUserModal({ onAddNewUser, onCancel, is_allowed_to_write }: any) {
  const [user, setUser] = React.useState({
    name: "",
    email: "",
    phone: "",
    role: "",
  });
  const [error, setError] = React.useState({
    name: "",
    email: "",
    phone: "",
    role: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [listOfRoles, setListOfRoles] = React.useState([]);
  const dispatch = useContext(ToastContext);
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  const emailRegExp = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
  function checkName() {
    if (checkIsEmpty(user.name)) {
      setError((error) => ({
        ...error,
        name: "Name cannot be empty",
      }));
      return false;
    }
    if (isValidName(user.name, false)) {
      setError((error) => ({
        ...error,
        name: "Enter a valid name",
      }));
      return false;
    }
    return true;
  }
  function checkEmail() {
    if (checkIsEmpty(user.email)) {
      setError((error) => ({
        ...error,
        email: "Email cannot be empty",
      }));
      return false;
    }
    if (!emailRegExp.test(user.email)) {
      setError((error) => ({
        ...error,
        email: "Enter a valid email",
      }));
      return false;
    }
    if (isValidEmailFirstChar(user.email)) {
      setError((error) => ({
        ...error,
        email: "Email cannot start with a number",
      }));
      return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, domain] = user.email.split("@");
    if (domain !== "zoop.one") {
      setError((error) => ({
        ...error,
        email: "Only Zoop emails are allowed",
      }));
      return false;
    }
    return true;
  }
  function checkPhoneNumber() {
    if (checkIsEmpty(user.phone)) {
      setError((error) => ({
        ...error,
        phone: "Phone number cannot be empty",
      }));
      return false;
    }
    if (!isValidPhoneNumber(user.phone)) {
      setError((error) => ({
        ...error,
        phone: "Enter a valid Phone number",
      }));
      return false;
    }
    if (user.phone[0] === "0") {
      setError((error) => ({
        ...error,
        phone: "Exclude 0 from beginning",
      }));
      return false;
    }
    if (user.phone.length > 10) {
      setError((error) => ({
        ...error,
        phone: "Phone number should be of 10 digit only!",
      }));
      return false;
    }
    return true;
  }
  function checkFormValidation() {
    return (
      user.name === "" ||
      error.name !== "" ||
      user.email === "" ||
      error.email !== "" ||
      user.phone === "" ||
      error.phone !== "" ||
      user.role === "" ||
      error.role !== ""
    );
  }
  async function addNewUser() {
    const allCheckPasses = [
      checkName(),
      checkEmail(),
      checkPhoneNumber(),
    ].every(Boolean);
    if (!allCheckPasses) {
      return;
    }
    setLoading(true);
    try {
      const response: any = await callPostApi("/kam", {
        ...user,
        phone: `+91${user.phone}`,
      });
      const addedUser = {
        name: response.data.displayName,
        email: response.data.email,
        joined_on: response.data.metadata.creationTime,
        role: user.role,
        status: response.data.disabled ? "DISABLED" : "ACTIVE",
        id: response.data.uid,
      };

      setLoading(false);
      onAddNewUser(addedUser);
    } catch (err: any) {
      setLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message:
            err.response.data.err + " TraceId is \n" + apmTraceId(apmInstance),
          copy: apmTraceId(apmInstance),
        },
      });
    }
  }
  async function getAllRoles() {
    setLoading(true);
    try {
      const { err, data }: any = await callGetApi("/roles");

      if (err) {
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: "Failed to fetch all permissions!",
          },
        });
        return;
      }
      setListOfRoles(data?.map((role: any) => role.role) || []);
    } catch (err: any) {
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: "Failed to fetch all permissions!",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAllRoles();
  }, []);

  return (
    <div
      className={`d-flex align-items-center justify-content-center ${popUpStyles.overlay}`}
    >
      <div className={`${popUpStyles.popUpContent} ${Styles.card}`}>
        <div className={`p-4 ${sharedStyle.cardHeaderBack}`}>
          <p className={`mb-0 ${sharedStyle.cardHeader}`}>Add User</p>
        </div>
        <div className="p-4 mx-2">
          <div>
            <Input
              no_shadow="true"
              type="text"
              name="user_name"
              id="user_name"
              autoComplete="off"
              value={user.name}
              hasError={error.name}
              placeholder="Enter user's Name"
              labelname="Name"
              maxLength={25}
              onChange={(event: any) => {
                setUser((user: any) => ({
                  ...user,
                  name: event.target.value,
                }));
                setError((error) => ({
                  ...error,
                  name: "",
                }));
              }}
              onBlur={checkName}
            />
            {error.name && <ErrorMessage>{error.name}</ErrorMessage>}
          </div>
          <div>
            <Input
              no_shadow="true"
              type="email"
              name="user_email"
              id="user_email"
              autoComplete="off"
              value={user.email}
              hasError={error.email}
              placeholder="Enter user's Email"
              labelname="Email"
              onChange={(event: any) => {
                setUser((user: any) => ({
                  ...user,
                  email: event.target.value,
                }));
                setError((error) => ({
                  ...error,
                  email: "",
                }));
              }}
              onBlur={checkEmail}
            />
            {error.email && <ErrorMessage>{error.email}</ErrorMessage>}
          </div>
          <div>
            <Input
              no_shadow="true"
              type="text"
              name="user_phone"
              id="user_phone"
              autoComplete="off"
              value={user.phone}
              hasError={error.phone}
              placeholder="Enter Phone number"
              labelname="Phone"
              maxLength={10}
              onChange={(event: any) => {
                setUser((user: any) => ({
                  ...user,
                  phone: event.target.value,
                }));
                setError((error) => ({
                  ...error,
                  phone: "",
                }));
              }}
              onBlur={checkPhoneNumber}
            />
            {error.phone && <ErrorMessage>{error.phone}</ErrorMessage>}
          </div>
          <div className="py-2">
            <InputDropdown
              labelname="Select a Role"
              optionsArray={listOfRoles}
              click={(role: any) => {
                setUser((user: any) => ({
                  ...user,
                  role: role,
                }));
                setError((error) => ({
                  ...error,
                  role: "",
                }));
              }}
              defaultValue={user.role}
              no_shadow="true"
            />
            {error.role && <ErrorMessage>{error.role}</ErrorMessage>}
          </div>
          <div className="pt-3 pb-2 d-flex align-items-center">
            <div className="pl-2 pr-4">
              <Checkbox id="auto_generate_password" checked disabled />
            </div>
            <div className="px-2">
              <p className={`mb-0 ${Styles.helperText}`}>
                Auto-generate password (Password will be emailed to the user)
              </p>
            </div>
          </div>
        </div>
        <div
          className="py-4 my-2 d-flex align-items-center justify-content-center"
          style={{ borderTop: "1px solid #ECEEEF" }}
        >
          <div className="px-2">
            <Button
              size={MEDIUM}
              style={cancelButtonStyle}
              hoveredStyle={cancelButtonHoverStyle}
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </div>
          <div className="px-2">
            <Button
              size={MEDIUM}
              disabled={!is_allowed_to_write || checkFormValidation()}
              isLoading={loading}
              style={primaryButtonStyle}
              hoveredStyle={primaryButtonHoverStyle}
              onClick={() => addNewUser()}
            >
              Add User
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewUserModal;

import React, { useContext, useEffect, useState } from "react";
import Styles from "./productCard.module.scss";
import sharedStyle from "../../../../shared/sharedStyle.module.scss";
import ArrowDown from "../../../../shared/svg/arrowDown";
import ProductInfoCard from "../product-info-card/productInfoCard";
import UpdateProductModal from "../update-product-modal/updateProductModal";
import { ACCENTCOLOR, SECONDARYCOLOR } from "../../../../shared/colors";
import { callGetApi } from "../../../../api/axios";
import { ToastContext } from "../../../../context/toastContext";
import { ERROR } from "../../../../utils/toastType";
import Loading from "../../../../shared/loading/loading";
import { ReactComponent as EditSVG } from "../../../../assets/images/edit.svg";
import { PermissionContext } from "../../../../context/permissionContext";
import { products } from "../../../../constants/navItems";
import { ApmContext } from "../../../../utils/apmProvider";

export default function ProductCard({
  product,
  setProduct,
  categoriesArray,
  setCategoriesArray,
}: any) {
  const { assets, permissions: permissionsType } =
    useContext(PermissionContext);
  const grant = assets[products][products].grant;
  const permissions = assets[products][products].permissions;
  const [cardOpen, setCardOpen] = useState<boolean[]>(
    Array(product.data.length).fill(false)
  );
  const [toggleUpdateProductModal, setToggleUpdateProductModal] =
    useState(false);
  const dispatch = useContext(ToastContext);
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  const [planLoading, setPlanLoading] = useState(true);
  const [plansMap, setPlansMap] = useState<any>(new Map());
  const [currentProduct, setCurrentProduct] = useState<any>();

  useEffect(() => {
    async function getProductPlans() {
      try {
        const response: any = await callGetApi(`/plans?data=get_all_plans`);

        const map = new Map();
        const plans = new Map();

        response?.data?.plans.map((item: any) => {
          if (typeof map.get(item.plan_name) === "undefined") {
            map.set(item.plan_name, item);
          }
        });

        product?.data.map((subProduct: any) => {
          const obj: any = {};
          map.forEach((value: any, key: string) => {
            Object.entries(value.pricing).forEach((val: any) => {
              if (val[0] === subProduct.arn) {
                obj[key] = val[1];
              }
            });
          });

          plans.set(subProduct.arn, {
            ...obj,
            lower_bound: subProduct.price_lower_bound,
          });
        });

        setPlansMap(plans);
        setPlanLoading(false);
      } catch (err: any) {
        setPlanLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message:
              err.response.data.err +
              " TraceId is \n" +
              apmTraceId(apmInstance),
            copy: apmTraceId(apmInstance),
          },
        });
      }
    }

    getProductPlans();
  }, []);

  function updatePlans(data: any, arn: string) {
    const currentData = plansMap.get(arn);
    currentData.Lite =
      typeof currentData.Lite === "number"
        ? data.Lite
        : {
            unit_price: data.Lite,
            ...data.addons,
          };
    currentData.Plus =
      typeof currentData.Plus === "number"
        ? data.Plus
        : {
            unit_price: data.Plus,
            ...data.addons,
          };
    currentData.Advance =
      typeof currentData.Advance === "number"
        ? data.Advance
        : {
            unit_price: data.Advance,
            ...data.addons,
          };
    currentData.Premium =
      typeof currentData.Premium === "number"
        ? data.Premium
        : {
            unit_price: data.Premium,
            ...data.addons,
          };
    currentData.Corporate =
      typeof currentData.Corporate === "number"
        ? data.Corporate
        : {
            unit_price: data.Corporate,
            ...data.addons,
          };
    currentData.Mini =
      typeof currentData.Mini === "number"
        ? data.Mini
        : {
            unit_price: data.Mini,
            ...data.addons,
          };
    currentData["Zoop PostPaid Testing Plan"] =
      typeof currentData["Zoop PostPaid Testing Plan"] === "number"
        ? data["Zoop PostPaid Testing Plan"]
        : {
            unit_price: data["Zoop PostPaid Testing Plan"],
            ...data.addons,
          };
    currentData["Zoop Testing Plan"] =
      typeof currentData["Zoop Testing Plan"] === "number"
        ? data["Zoop Testing Plan"]
        : {
            unit_price: data["Zoop Testing Plan"],
            ...data.addons,
          };
    plansMap.set(arn, currentData);
  }

  return (
    <>
      <div className="p-3">
        <div className="d-inline-flex py-2">
          <div
            className="d-flex align-items-center"
            onClick={() => setProduct("")}
            style={{ cursor: "pointer" }}
          >
            <div className={Styles.rotate}>
              <ArrowDown color={SECONDARYCOLOR} />
            </div>
            <p className={`mb-0 ${Styles.back}`}>back</p>
          </div>
        </div>
        <div className="py-3">
          <div className={sharedStyle.card}>
            <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
              <div className="d-flex align-items-center">
                <p className={`mb-0 ${sharedStyle.cardHeader}`}>
                  {product.name} Verification
                </p>
                <div className="pl-2 ml-auto">
                  {/* <Button
                    size={LARGE}
                    style={primaryButtonStyle}
                    hoveredStyle={primaryButtonHoverStyle}
                    onClick={() => setaddNewProductCategoryModal(true)}
                  >
                    Add new product
                  </Button> */}
                </div>
              </div>
            </div>
            <>
              <div style={{ overflowX: "auto", minHeight: "100px" }}>
                <table style={{ width: "100%" }}>
                  <thead className={sharedStyle.tableHeaderBack}>
                    <tr>
                      <th
                        className={Styles.name_column}
                        style={{
                          padding: "15px 15px 15px 25px",
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <p className={`mb-0 ${Styles.tableHeaderText}`}>
                            ARN ID
                          </p>
                        </div>
                      </th>
                      <th className={Styles.updateBtn}></th>
                      <th className={Styles.right_arrow_column}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {product.data.length > 0 ? (
                      product.data.map((subProduct: any, index: number) => {
                        return (
                          <>
                            <tr
                              key={`row-${index}`}
                              style={{ borderBottom: "1px solid #eceeef" }}
                              className={Styles.table_row_wrapper}
                            >
                              <td
                                className={Styles.name_column}
                                style={{ padding: "15px 15px 15px 25px" }}
                                onClick={() => {
                                  const newArr = [...cardOpen];
                                  newArr[index] = !cardOpen[index];
                                  setCardOpen(newArr);
                                }}
                              >
                                <p className={`mb-0 ${Styles.tableBodyText}`}>
                                  {subProduct.arn}
                                </p>
                              </td>

                              <td
                                className={Styles.updateBtn}
                                onClick={() => {
                                  setToggleUpdateProductModal(true);
                                  setCurrentProduct(subProduct);
                                }}
                              >
                                {<EditSVG />}
                              </td>

                              <td
                                className={Styles.right_arrow_column}
                                onClick={() => {
                                  const newArr = [...cardOpen];
                                  newArr[index] = !cardOpen[index];
                                  setCardOpen(newArr);
                                }}
                              >
                                <p className={`mb-0 ${Styles.tableBodyText}`}>
                                  <div
                                    className={`${
                                      cardOpen[index]
                                        ? Styles.rotateUp
                                        : Styles.rotateDown
                                    }`}
                                  >
                                    <ArrowDown />
                                  </div>
                                </p>
                              </td>
                            </tr>
                            {cardOpen[index] && (
                              <tr
                                key={`card-${index}`}
                                style={{
                                  borderBottom: "1px solid #eceeef",
                                  cursor: "default",
                                }}
                                className={Styles.table_row_wrapper}
                              >
                                {planLoading ? (
                                  <div
                                    style={{ height: "150px" }}
                                    className="d-flex align-items-center justify-content-center"
                                  >
                                    <Loading loadingColor={ACCENTCOLOR} />
                                  </div>
                                ) : (
                                  <ProductInfoCard
                                    key={`${index}`}
                                    product={subProduct}
                                    plan={plansMap.get(subProduct.arn)}
                                    updatePlansMap={(data: any) => {
                                      updatePlans(data, subProduct.arn);
                                    }}
                                    is_allows_to_write={
                                      permissions[
                                        `${permissionsType.write}_${grant}`
                                      ]
                                    }
                                  />
                                )}
                              </tr>
                            )}
                          </>
                        );
                      })
                    ) : (
                      <tr
                        style={{ height: "400px" }}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <td className="mb-0">No Product found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          </div>
          {toggleUpdateProductModal && (
            <UpdateProductModal
              updateProduct={(updatedData: any) => {
                setToggleUpdateProductModal(false);
                const newCategoryArr = categoriesArray;
                for (const item of newCategoryArr) {
                  if (item.name === product.name) {
                    for (const subItem of item.data) {
                      if (subItem.arn === currentProduct.arn) {
                        subItem.name = updatedData.name;
                        subItem.default_free_transactions =
                          updatedData.default_free_transactions;
                        subItem.short_description =
                          updatedData.short_description;
                        subItem.price_lower_bound =
                          updatedData.price_lower_bound;
                      }
                    }
                  }
                }
                setCategoriesArray(newCategoryArr);
              }}
              product={product}
              currentProduct={currentProduct}
              onCancel={() => setToggleUpdateProductModal(false)}
              is_allows_to_write={
                permissions[`${permissionsType.write}_${grant}`]
              }
            />
          )}
        </div>
      </div>
    </>
  );
}

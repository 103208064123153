import React, { useState, useEffect, useContext } from "react";
import { callGetApi } from "../../../../api/axios";
import { ToastContext } from "../../../../context/toastContext";
import { ACCENTCOLOR, SECONDARYCOLOR } from "../../../../shared/colors";
import Loading from "../../../../shared/loading/loading";
import Sort from "../../../../shared/svg/sort";
import { ERROR } from "../../../../utils/toastType";
import Styles from "./pendingProducts.module.scss";
import sharedStyle from "../../../../shared/sharedStyle.module.scss";
import { durationStyles } from "../../../../constants/durationStyles";
import { getDuration } from "../../../../utils/getDuration";
import { getDurationStyle } from "../../../../utils/getDurationStyle";
import { monthName } from "../../../../utils/getMonth";
import Close from "../../../../shared/svg/close";
import Pagination from "../../../../shared/pagination/pagination";
import FilterDropdown from "../../../../shared/inTable-filter-dropdown/filterDropdown";
import ArrowDown from "../../../../shared/svg/arrowDown";
import { Link } from "react-router-dom";
import { ApmContext } from "../../../../utils/apmProvider";

function PendingProducts() {
  const sortTypes = {
    ascending: "Ascending",
    descending: "Descending",
  };
  const sortingColumns = {
    product_name: "name",
    products_requested: "products_requested",
    requested_on: "requested_on",
  };
  const [pageLoading, setPageLoading] = useState(true);
  const [defaultPendingProducts, setDefaultPendingProducts] = useState([]);
  const [pendingProducts, setPendingProducts] = useState([]);
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  const [filter, setFilters] = useState<any[]>([]);
  const [productNameFilter, setProductNameFilter] = useState<String[]>([]);
  const [serviceRequestedFilter, setServiceRequestedFilter] = useState<
    String[]
  >([]);
  const [listOfPendingProductNames, setListOfPendingProductNames] = useState<
    String[]
  >([]);
  const [listOfServiceRequested, setListOfServiceRequested] = useState<
    String[]
  >([]);
  const [filterBasedOnSorting, setFilterBasedOnSorting] = React.useState({
    sortType: "",
    sortingColumn: "",
  });
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [postPerPage] = useState(20);
  const dispatch = useContext(ToastContext);
  const indexOfLastPendingProduct = activePageNumber * postPerPage;
  const indexOfFirstPendingProduct = indexOfLastPendingProduct - postPerPage;

  useEffect(() => {
    setFilters([...productNameFilter, ...serviceRequestedFilter]);
    setActivePageNumber(1);
  }, [productNameFilter, serviceRequestedFilter]);

  useEffect(() => {
    async function getPendingProductApproval() {
      setPageLoading(true);
      try {
        const response: any = await callGetApi(
          "/organisations?status=product_pending_approval"
        );
        const pendingList = response.data.map((data: any) => ({
          name: data.name,
          products_requested: data.products_requested.length,
          requested_on: data.requested_on,
          id: data._id,
        }));

        const sortList = pendingList?.length
          ? pendingList.sort((a: any, b: any) => {
              return b.requested_on - a.requested_on;
            })
          : [];

        setPageLoading(false);
        setDefaultPendingProducts(sortList);
        setPendingProducts(sortList);
        setFilterBasedOnSorting((filterBasedOnSorting) => ({
          ...filterBasedOnSorting,
          sortType: sortTypes.descending,
          sortingColumn: sortingColumns.requested_on,
        }));
      } catch (error: any) {
        setPageLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message:
              error?.response?.data?.err +
                " TraceId is \n" +
                apmTraceId(apmInstance) || "Something went wrong!",
            copy: apmTraceId(apmInstance),
          },
        });
      }
    }
    getPendingProductApproval();
  }, [dispatch, sortTypes.descending, sortingColumns.requested_on]);

  const filteredPendingProductList = pendingProducts.filter((product: any) => {
    return (
      filter.length === 0 ||
      // eslint-disable-next-line
      ((productNameFilter.length == 0 ||
        productNameFilter.includes(product.name)) &&
        // eslint-disable-next-line
        (serviceRequestedFilter.length == 0 ||
          serviceRequestedFilter.includes(String(product.products_requested))))
    );
  });

  // Sort the column in descending order
  function sortDescending(leftParam: any, rightParam: any) {
    let comparison = 0;
    if (leftParam > rightParam) {
      comparison = -1;
    } else if (leftParam < rightParam) {
      comparison = 1;
    }
    return comparison;
  }
  // Sort the column in ascending order
  function sortAscending(leftParam: any, rightParam: any) {
    let comparison = 0;
    if (leftParam > rightParam) {
      comparison = 1;
    } else if (leftParam < rightParam) {
      comparison = -1;
    }
    return comparison;
  }
  // decide type of sort for specific column
  function sort(type: string, columnName: string) {
    const sortedArray = pendingProducts.slice().sort((a: any, b: any) => {
      let leftParam;
      let rightParam;
      if (columnName === sortingColumns.products_requested) {
        leftParam = Number(a[columnName]);
        rightParam = Number(b[columnName]);
      } else {
        leftParam = a[columnName].toLowerCase();
        rightParam = b[columnName].toLowerCase();
      }
      if (type === sortTypes.ascending) {
        return sortAscending(leftParam, rightParam);
      } else {
        return sortDescending(leftParam, rightParam);
      }
    });
    setPendingProducts(sortedArray);
  }
  // decide which sorting to apply based upon column name
  function applySorting(columnName: string) {
    if (
      filterBasedOnSorting.sortType === sortTypes.descending &&
      filterBasedOnSorting.sortingColumn === columnName
    ) {
      setFilterBasedOnSorting((filterBasedOnSorting) => ({
        ...filterBasedOnSorting,
        sortType: "",
        sortingColumn: columnName,
      }));
      setPendingProducts(defaultPendingProducts);
    } else if (
      filterBasedOnSorting.sortType === sortTypes.ascending &&
      filterBasedOnSorting.sortingColumn === columnName
    ) {
      setFilterBasedOnSorting((filterBasedOnSorting) => ({
        ...filterBasedOnSorting,
        sortType: sortTypes.descending,
        sortingColumn: columnName,
      }));
      sort(sortTypes.descending, columnName);
    } else {
      setFilterBasedOnSorting((filterBasedOnSorting) => ({
        ...filterBasedOnSorting,
        sortType: sortTypes.ascending,
        sortingColumn: columnName,
      }));
      sort(sortTypes.ascending, columnName);
    }
  }
  // get all the names of the Plans in the given list
  function getAllPendingProductNames() {
    const pendingProductsName = pendingProducts.map(
      (pendingProduct: any) => pendingProduct.name
    );
    return setListOfPendingProductNames(
      pendingProductsName.filter(
        (pendingProduct_name: string, index: number) =>
          pendingProductsName.indexOf(pendingProduct_name) === index
      )
    );
  }

  //get all service requested
  function getAllServiceRequested() {
    const pendingServiceRequests = pendingProducts.map((pendingProduct: any) =>
      pendingProduct.products_requested.toString()
    );
    return setListOfServiceRequested(
      pendingServiceRequests.filter(
        (pendingServiceRequested: string, index: number) =>
          pendingServiceRequests.indexOf(pendingServiceRequested) === index
      )
    );
  }

  function removeInTableFilter(value: any) {
    if (productNameFilter.includes(value)) {
      return setProductNameFilter(
        productNameFilter.filter((filter) => filter !== value)
      );
    }
    if (serviceRequestedFilter.includes(value)) {
      return setServiceRequestedFilter(
        serviceRequestedFilter.filter((filter) => filter !== value)
      );
    }
  }

  function clearAllFilters() {
    setProductNameFilter([]);
    setServiceRequestedFilter([]);
  }

  return (
    <div className="py-3">
      <div className="pb-2 d-flex align-items-center">
        <Link to="/application/home" className={sharedStyle.linkDecoration}>
          <div
            className="d-flex align-items-center"
            style={{ cursor: "pointer" }}
          >
            <div className={Styles.rotate90}>
              <ArrowDown color={SECONDARYCOLOR} />
            </div>
            <p className={`mb-0 ${sharedStyle.back}`}>back</p>
          </div>
        </Link>
      </div>
      <div className="py-4">
        <div className={sharedStyle.card}>
          <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
            <div className="d-flex align-items-center">
              <p className={`mb-0 ${sharedStyle.cardHeader}`}>
                Pending Product Approval
              </p>
            </div>
          </div>
          {filter.length > 0 && (
            <div
              className={`p-2 d-flex align-items-center flex-row-reverse flex-wrap ${Styles.filterBack}`}
            >
              <p
                className={`px-2 mb-0 ${Styles.clearAll}`}
                onClick={() => clearAllFilters()}
              >
                Clear All
              </p>
              {filter.map((filter: any, index: number) => {
                return (
                  <div className="p-1" key={index}>
                    <div
                      className={`d-flex align-items-center ${Styles.filterWrapper}`}
                    >
                      <p className={`mb-0 pl-2 ${Styles.filterName}`}>
                        {filter}
                      </p>
                      <div
                        className="px-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => removeInTableFilter(filter)}
                      >
                        <Close classes={Styles.close} width="8" height="8" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {pageLoading ? (
            <div
              style={{ height: "360px", backgroundColor: "white" }}
              className="d-flex align-items-center justify-content-center"
            >
              <Loading loadingColor={ACCENTCOLOR} />
            </div>
          ) : (
            // Make the table and fill the details
            <>
              <div style={{ overflowX: "auto", minHeight: "400px" }}>
                <table style={{ width: "100%" }}>
                  <thead className={sharedStyle.tableHeaderBack}>
                    <tr>
                      <th
                        className={Styles.account_name_column}
                        style={{ padding: "15px 15px 15px 25px" }}
                      >
                        <div className="d-flex align-items-center">
                          <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                            Name
                          </p>
                          <div className="px-2">
                            <Sort
                              classes={`${
                                filterBasedOnSorting.sortType ===
                                  sortTypes.descending &&
                                filterBasedOnSorting.sortingColumn ===
                                  sortingColumns.product_name
                                  ? Styles.rotate
                                  : ""
                              } ${
                                filterBasedOnSorting.sortType !== "" &&
                                filterBasedOnSorting.sortingColumn ===
                                  sortingColumns.product_name
                                  ? Styles.active
                                  : Styles.sort
                              }`}
                              click={() =>
                                applySorting(sortingColumns.product_name)
                              }
                            />
                          </div>
                          <FilterDropdown
                            width="300px"
                            height="200px"
                            optionArray={listOfPendingProductNames}
                            filters={productNameFilter}
                            onSelect={(option: any) => {
                              setActivePageNumber(1);
                              if (productNameFilter.includes(option)) {
                                setProductNameFilter(
                                  productNameFilter.filter(
                                    (data: any) => data !== option
                                  )
                                );
                                return;
                              }
                              setProductNameFilter([
                                ...productNameFilter,
                                option,
                              ]);
                            }}
                            toggleReset={productNameFilter.length !== 0}
                            onReset={() => setProductNameFilter([])}
                          >
                            <div
                              className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                              onClick={() => getAllPendingProductNames()}
                            >
                              <ArrowDown classes={Styles.arrow} />
                            </div>
                          </FilterDropdown>
                        </div>
                      </th>

                      <th className={Styles.service_requested}>
                        <div className="d-flex align-items-center">
                          <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                            Service Requested
                          </p>
                          <div className="px-2">
                            <Sort
                              classes={`${
                                filterBasedOnSorting.sortType ===
                                  sortTypes.descending &&
                                filterBasedOnSorting.sortingColumn ===
                                  sortingColumns.products_requested
                                  ? Styles.rotate
                                  : ""
                              } ${
                                filterBasedOnSorting.sortType !== "" &&
                                filterBasedOnSorting.sortingColumn ===
                                  sortingColumns.products_requested
                                  ? Styles.active
                                  : Styles.sort
                              }`}
                              click={() =>
                                applySorting(sortingColumns.products_requested)
                              }
                            />
                          </div>
                          <FilterDropdown
                            width="300px"
                            height="200px"
                            optionArray={listOfServiceRequested}
                            filters={serviceRequestedFilter}
                            onSelect={(option: any) => {
                              setActivePageNumber(1);
                              if (serviceRequestedFilter.includes(option)) {
                                setServiceRequestedFilter(
                                  serviceRequestedFilter.filter(
                                    (data: any) => data !== option
                                  )
                                );
                                return;
                              }
                              setServiceRequestedFilter([
                                ...serviceRequestedFilter,
                                option,
                              ]);
                            }}
                            toggleReset={serviceRequestedFilter.length !== 0}
                            onReset={() => setServiceRequestedFilter([])}
                          >
                            <div
                              className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                              onClick={() => getAllServiceRequested()}
                            >
                              <ArrowDown classes={Styles.arrow} />
                            </div>
                          </FilterDropdown>
                        </div>
                      </th>
                      <th className={Styles.date}>
                        <div className="d-flex align-items-center">
                          <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                            Date
                          </p>
                          <div className="px-2">
                            <Sort
                              classes={`${
                                filterBasedOnSorting.sortType ===
                                  sortTypes.descending &&
                                filterBasedOnSorting.sortingColumn ===
                                  sortingColumns.requested_on
                                  ? Styles.rotate
                                  : ""
                              } ${
                                filterBasedOnSorting.sortType !== "" &&
                                filterBasedOnSorting.sortingColumn ===
                                  sortingColumns.requested_on
                                  ? Styles.active
                                  : Styles.sort
                              }`}
                              click={() =>
                                applySorting(sortingColumns.requested_on)
                              }
                            />
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPendingProductList.length > 0 ? (
                      filteredPendingProductList
                        .slice(
                          indexOfFirstPendingProduct,
                          indexOfLastPendingProduct
                        )
                        .map((pendingProduct: any, index: number) => {
                          const date = new Date(
                            Number(pendingProduct.requested_on)
                          );
                          const duration = getDuration(date);
                          const style = getDurationStyle(duration, 7, 3);
                          return (
                            <tr
                              key={pendingProduct.id}
                              // className={Styles.table_row_wrapper}
                              // style={{ borderBottom: "1px solid #eceeef" }}
                              style={
                                index !== filteredPendingProductList.length - 1
                                  ? { borderBottom: "1px solid #eceeef" }
                                  : { borderBottom: 0 }
                              }
                            >
                              <td className={Styles.account_name_column}>
                                <Link
                                  to={`/application/accounts/${pendingProduct.id}`}
                                  className={`mb-0 ${Styles.accountName}`}
                                >
                                  {pendingProduct.name}
                                </Link>
                              </td>

                              {/* <td
                                className={Styles.account_name_column}
                                style={{ padding: "15px 15px 15px 25px" }}
                              >
                                <p className={`mb-0 ${Styles.tableBodyText}`}>
                                  {pendingProduct.name}
                                </p>
                              </td> */}
                              <td
                                className={Styles.service_requested}
                                style={{ padding: "15px 15px 15px 25px" }}
                              >
                                <p className={`mb-0 ${Styles.tableBodyText}`}>
                                  {pendingProduct.products_requested}
                                </p>
                              </td>
                              <td className={Styles.date}>
                                <div
                                  className={`mb-0 ${Styles.duration}`}
                                  style={
                                    style === durationStyles.DANGER
                                      ? { color: "#d64747" }
                                      : style === durationStyles.WARNING
                                      ? { color: "#f4a63b" }
                                      : { color: "#31b975" }
                                  }
                                >
                                  {duration}
                                </div>
                                <p
                                  className={`mb-0 ${sharedStyle.tableBodyText}`}
                                >
                                  {`${monthName(
                                    date.getMonth()
                                  )} ${date.getDate()}, ${date.getFullYear()}`}
                                </p>
                              </td>
                            </tr>
                          );
                        })
                    ) : (
                      <tr
                        style={{ height: "400px" }}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <td className="mb-0">No Data found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>{" "}
              {filteredPendingProductList.length > 0 && (
                <div className="px-4 py-3 d-flex align-items-center">
                  <p className={`mb-0 ${Styles.numberOfRows}`}>
                    {
                      filteredPendingProductList.slice(
                        indexOfFirstPendingProduct,
                        indexOfLastPendingProduct
                      ).length
                    }{" "}
                    rows per page
                  </p>
                  <div className="py-2 ml-auto">
                    <Pagination
                      activePageNumber={activePageNumber}
                      total={filteredPendingProductList.length}
                      postPerPage={postPerPage}
                      navigateToPage={(value: number) => {
                        setActivePageNumber(value);
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PendingProducts;

import React, { useContext, useEffect, useState } from "react";
import { callGetApi, callPutApi } from "../../../../../../api/axios";
import { orgStatus } from "../../../../../../constants/orgStatus";
import { ToastContext } from "../../../../../../context/toastContext";
import Button from "../../../../../../shared/button/button";
import { COMPRESSED } from "../../../../../../shared/buttonSize";
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  successButtonHoverStyle,
  successButtonStyle,
} from "../../../../../../shared/buttonStyles";
import { ACCENTCOLOR, PRIMARYCOLOR } from "../../../../../../shared/colors";
import ErrorMessage from "../../../../../../shared/error-message/errorMessage";
import InputDropdown from "../../../../../../shared/input-dropdown/inputDropdown";
import Input from "../../../../../../shared/input/input";
import KeyValuePair from "../../../../../../shared/key-value-pair/keyValuePair";
import Loading from "../../../../../../shared/loading/loading";
import sharedStyle from "../../../../../../shared/sharedStyle.module.scss";
import RupeeSVG from "../../../../../../shared/svg/rupeeSvg";
import UpdateSvg from "../../../../../../shared/svg/updateSvg";
import { ERROR } from "../../../../../../utils/toastType";
import { checkIsEmpty } from "../../../../../../utils/validation";
import { ApmContext } from "../../../../../../utils/apmProvider";

export default function AdditionalInfo({
  org_id = "",
  accountInfo = {},
  is_allowed_to_write,
}: any) {
  const { traceId, apmInstance, apmTraceId } = React.useContext(ApmContext);
  const [additionalInfo, setAdditionalInfo] = useState({
    expected_transaction_volume: {
      min_value: 0,
      max_value: 0,
    },
    opportunity_cost: 0,
  });
  const [defaultAdditionalInfo, setDefaultAdditionalInfo] = useState({
    expected_transaction_volume: {
      min_value: 0,
      max_value: 0,
    },
    opportunity_cost: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [updateAdditionalInfoLoading, setUpdateAdditionalInfoLoading] =
    useState(false);
  const [toggleUpdateAdditionalInfo, setToggleUpdateAdditionalInfo] =
    useState(false);
  const [error, setError] = useState({
    opportunity_cost: "",
  });
  const dispatch = useContext(ToastContext);

  const dropdown_options = [
    { key: "0 - 25,000", value: { min_value: 0, max_value: 25000 } },
    { key: "25,000 - 50,000", value: { min_value: 25000, max_value: 50000 } },
    { key: "50,000 - 100,000", value: { min_value: 50000, max_value: 100000 } },
    {
      key: "100,000 & above",
      value: { min_value: 100000, max_value: 1000000 },
    },
  ];
  useEffect(() => {
    if (org_id) {
      getAdditionalInfo();
    }
    async function getAdditionalInfo() {
      setIsLoading(true);
      try {
        const { data }: any = await callGetApi(
          `organisations/${org_id}?data=additional_info`
        );
        setIsLoading(false);
        setAdditionalInfo(data);
        setDefaultAdditionalInfo(data);
      } catch (err: any) {
        setIsLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message:
              err.response.data.err +
              " TraceId is \n" +
              apmTraceId(apmInstance),
            copy: apmTraceId(apmInstance),
          },
        });
      }
    }
  }, [org_id, dispatch]);

  // function to update additional info
  async function updateAdditionalInfo() {
    if (!checkOpportunityCost()) return;
    setUpdateAdditionalInfoLoading(true);
    try {
      const { data }: any = await callPutApi(
        `/organisations/${org_id}?action=update_additional_info`,
        {
          expected_transaction_volume:
            additionalInfo.expected_transaction_volume,
          opportunity_cost: Number(additionalInfo.opportunity_cost),
        }
      );
      setAdditionalInfo(data);
      setDefaultAdditionalInfo(data);
      setUpdateAdditionalInfoLoading(false);
      setToggleUpdateAdditionalInfo(false);
    } catch (err: any) {
      setUpdateAdditionalInfoLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message:
            err.response.data.err + " TraceId is \n" + apmTraceId(apmInstance),
          copy: apmTraceId(apmInstance),
        },
      });
    }
  }

  // function to check opportunity cost
  function checkOpportunityCost() {
    if (checkIsEmpty(additionalInfo?.opportunity_cost.toString())) {
      setError({
        opportunity_cost: "Cost cannot be empty",
      });
      return false;
    }
    if (additionalInfo?.opportunity_cost < 0) {
      setError({
        opportunity_cost: "Cost cannot be negative",
      });
      return false;
    }
    if (!Number.isInteger(+additionalInfo?.opportunity_cost)) {
      setError({
        opportunity_cost: "No decimal values allowed",
      });
      return false;
    }
    return true;
  }
  const loading = (
    <div
      style={{ height: "250px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Loading loadingColor={ACCENTCOLOR} />
    </div>
  );
  return (
    <div className={sharedStyle.card}>
      <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
        <div className="d-flex align-items-center">
          <p className={`mb-0 ${sharedStyle.cardHeader}`}>Additional Info</p>
          <div className="ml-auto">
            {isLoading ? (
              <div>--</div>
            ) : is_allowed_to_write ? (
              toggleUpdateAdditionalInfo ? (
                <div className="d-flex align-items-center">
                  <div className="px-2">
                    <Button
                      size={COMPRESSED}
                      style={cancelButtonStyle}
                      disabled={updateAdditionalInfoLoading}
                      hoveredStyle={cancelButtonHoverStyle}
                      onClick={() => {
                        setAdditionalInfo(defaultAdditionalInfo);
                        setToggleUpdateAdditionalInfo(false);
                        setError({
                          opportunity_cost: "",
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="px-2">
                    <Button
                      size={COMPRESSED}
                      style={successButtonStyle}
                      disabled={updateAdditionalInfoLoading}
                      hoveredStyle={successButtonHoverStyle}
                      onClick={() => updateAdditionalInfo()}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              ) : accountInfo?.status === orgStatus.DISABLED ? null : (
                <UpdateSvg
                  click={() => {
                    setToggleUpdateAdditionalInfo(true);
                  }}
                />
              )
            ) : null}
          </div>
        </div>
      </div>
      {isLoading || updateAdditionalInfoLoading ? (
        loading
      ) : (
        <div className="p-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                {toggleUpdateAdditionalInfo ? (
                  <div className="py-2">
                    <InputDropdown
                      labelname="Expected Transaction Volume"
                      optionsArray={dropdown_options.map(
                        (option) => option.key
                      )}
                      click={(key: string) => {
                        const selected_value = dropdown_options.find(
                          (option) => option.key === key
                        );
                        const value: any = selected_value?.value;
                        setAdditionalInfo((additionalInfo) => ({
                          ...additionalInfo,
                          expected_transaction_volume: value,
                        }));
                      }}
                      defaultValue={`${additionalInfo?.expected_transaction_volume.min_value} - ${additionalInfo?.expected_transaction_volume.max_value}`}
                      no_shadow="true"
                    />
                  </div>
                ) : (
                  <KeyValuePair
                    className="py-4"
                    label="Expected Transaction Volume"
                    value={
                      `${additionalInfo?.expected_transaction_volume.min_value} - ${additionalInfo?.expected_transaction_volume.max_value}` ??
                      "NA"
                    }
                  />
                )}
              </div>
              <div className="col-md-6">
                {toggleUpdateAdditionalInfo ? (
                  <div>
                    <Input
                      no_shadow="true"
                      type="number"
                      name="opportunity_cost"
                      id="opportunity_cost"
                      autoComplete="off"
                      value={additionalInfo?.opportunity_cost}
                      hasError={error.opportunity_cost}
                      placeholder="Enter Opportunity Cost"
                      labelname="Opportunity"
                      onChange={(event: any) => {
                        if (event.target.value.length > 10) {
                          return;
                        }
                        setAdditionalInfo((additionalInfo: any) => ({
                          ...additionalInfo,
                          opportunity_cost: event.target.value,
                        }));
                        setError((error) => ({
                          ...error,
                          opportunity_cost: "",
                        }));
                      }}
                      onBlur={checkOpportunityCost}
                    />
                    {error.opportunity_cost && (
                      <ErrorMessage>{error.opportunity_cost}</ErrorMessage>
                    )}
                  </div>
                ) : (
                  <KeyValuePair
                    className="py-4"
                    label="Opportunity Cost"
                    value={additionalInfo?.opportunity_cost ?? "NA"}
                  >
                    <RupeeSVG width="8" height="12" color={PRIMARYCOLOR} />
                  </KeyValuePair>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useCallback, useContext, useEffect } from "react";
import Styles from "../billing.module.scss";
import sharedStyle from "../../../../../../shared/sharedStyle.module.scss";
import Button from "../../../../../../shared/button/button";
import { COMPRESSED } from "../../../../../../shared/buttonSize";
import {
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../../../shared/buttonStyles";
import RupeeSVG from "../../../../../../shared/svg/rupeeSvg";
import { ACCENTCOLOR, PRIMARYCOLOR } from "../../../../../../shared/colors";
import AddPaymentModal from "../add-payment-modal/addPaymentModal";
import { callGetApi } from "../../../../../../api/axios";
import { ERROR } from "../../../../../../utils/toastType";
import Loading from "../../../../../../shared/loading/loading";
import { monthName } from "../../../../../../utils/getMonth";
import { ToastContext } from "../../../../../../context/toastContext";
import {
  numberWithCommas,
  roundOfDecimal,
} from "../../../../../../utils/updateAmount";
import {
  orderTypes,
  paymentTypes,
} from "../../../../../../constants/orderTypes";
import { orgStatus } from "../../../../../../constants/orgStatus";
import { ApmContext } from "../../../../../../utils/apmProvider";

function AdvanceReceipts({
  id,
  org_status,
  planSubscribed,
  getUpdatedBillingInfo,
  onUpdateAmount,
  getUpdateAccountInfo,
  country_code,
  is_allowed_to_write,
}: any) {
  const [toggleAddPaymentPopUp, setToggleAddPaymentPopUp] =
    React.useState(false);
  const [advanceReceiptsLoading, setAdvanceReceiptsLoading] =
    React.useState(false);
  const [status] = React.useState(org_status);
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  const dispatch = useContext(ToastContext);
  const [advanceReceipts, setAdvanceReceipts] = React.useState([]);
  const loading = (
    <div
      style={{ height: "250px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Loading loadingColor={ACCENTCOLOR} />
    </div>
  );
  const emptyState = (
    <div
      style={{ height: "250px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <h5 className="mb-0">No Receipts Found</h5>
    </div>
  );
  const getBillingAdvanceReceipts = useCallback(async () => {
    setAdvanceReceiptsLoading(true);
    try {
      const response: any = await callGetApi(
        `organisations/${id}?data=billing_advance_receipts`
      );
      setAdvanceReceiptsLoading(false);
      setAdvanceReceipts(response.data);
    } catch (err: any) {
      setAdvanceReceiptsLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message:
            err.response.data.err + " TraceId is \n" + apmTraceId(apmInstance),
          copy: apmTraceId(apmInstance),
        },
      });
    }
  }, [id, dispatch]);
  useEffect(() => {
    getBillingAdvanceReceipts();
  }, [getBillingAdvanceReceipts]);
  return (
    <div className={sharedStyle.card}>
      <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
        <div className="d-flex align-items-center">
          <p className={`mb-0 ${sharedStyle.cardHeader}`}>Advance Receipts</p>
          {is_allowed_to_write && (
            <div className="ml-auto px-2">
              <Button
                size={COMPRESSED}
                style={primaryButtonStyle}
                hoveredStyle={primaryButtonHoverStyle}
                onClick={() => setToggleAddPaymentPopUp(true)}
                disabled={status === orgStatus.DISABLED}
              >
                Add Payment
              </Button>
            </div>
          )}
        </div>
      </div>
      {advanceReceiptsLoading ? (
        loading
      ) : advanceReceipts.length > 0 ? (
        <div style={{ overflowX: "auto" }}>
          <table style={{ width: "100%" }}>
            <thead className={sharedStyle.tableHeaderBack}>
              <tr>
                <th className={Styles.date}>
                  <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>Date</p>
                </th>
                <th className={Styles.payment_id}>
                  <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                    Payment ID
                  </p>
                </th>
                <th className={Styles.particulars}>
                  <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                    Particulars
                  </p>
                </th>
                <th className={Styles.type}>
                  <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                    Order Type
                  </p>
                </th>
                <th className={Styles.type}>
                  <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                    Payment Type
                  </p>
                </th>
                <th className={Styles.mode}>
                  <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>Mode</p>
                </th>
                <th className={Styles.amount}>
                  <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                    Amount
                  </p>
                </th>
                {/* <th className={Styles.adv_receipt}>
                  <p
                    className={`mb-0 ${sharedStyle.tableHeaderText}`}
                    style={{ textAlign: "center" }}
                  >
                    Adv Receipt
                  </p>
                </th> */}
              </tr>
            </thead>
            <tbody className={Styles.tableWrapper}>
              {advanceReceipts.map((receipt: any, index: number) => {
                const date = new Date(receipt.createdAt);
                return (
                  <tr
                    key={index}
                    style={
                      index !== advanceReceipts.length - 1
                        ? { borderBottom: "1px solid #eceeef" }
                        : { borderBottom: 0 }
                    }
                  >
                    <td className={Styles.date}>
                      <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                        {`${monthName(
                          date.getMonth()
                        )} ${date.getDate()}, ${date.getFullYear()}`}
                      </p>
                    </td>
                    <td className={Styles.payment_id}>
                      <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                        {receipt?.payment_id}
                      </p>
                    </td>
                    <td className={Styles.particulars}>
                      <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                        {receipt?.particulars}
                      </p>
                    </td>
                    <td className={Styles.type}>
                      <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                        {
                          orderTypes.find(
                            (orderType) => orderType.key === receipt?.order_type
                          )?.value
                        }
                      </p>
                    </td>
                    <td className={Styles.type}>
                      <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                        {
                          paymentTypes.find(
                            (paymentType) =>
                              paymentType.key === receipt?.payment_type
                          )?.value
                        }
                      </p>
                    </td>
                    <td className={Styles.mode}>
                      <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                        {receipt?.mode ?? "NA"}
                      </p>
                    </td>
                    <td className={Styles.amount}>
                      <div className="d-flex align-items-center">
                        <div className="pr-2">
                          <RupeeSVG
                            width="8"
                            height="12"
                            color={PRIMARYCOLOR}
                          />
                        </div>
                        <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                          {numberWithCommas(roundOfDecimal(receipt?.amount))}
                        </p>
                      </div>
                    </td>
                    {/* <td className={Styles.adv_receipt}>
                      <div className="d-flex justify-content-center">
                        <a
                          // href={receipt?.advance_receipt_link}
                          href="https://google.com"
                          target="_blank"
                          rel="noreferrer"
                          className={`mb-0 ${Styles.rateCard}`}
                        >
                          View
                        </a>
                      </div>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        emptyState
      )}
      {toggleAddPaymentPopUp && (
        <AddPaymentModal
          country_code={country_code}
          planSubscribed={planSubscribed}
          onCancel={() => setToggleAddPaymentPopUp(false)}
          onProceed={(payment_type: string, amount: number) => {
            setToggleAddPaymentPopUp(false);
            getBillingAdvanceReceipts();
            getUpdatedBillingInfo();

            if (payment_type === "Balance_Top_Up") {
              onUpdateAmount(amount);
              getUpdateAccountInfo();
            }
            if (payment_type !== "Balance_Top_Up") {
              getUpdateAccountInfo();
            }
          }}
        />
      )}
    </div>
  );
}

export default AdvanceReceipts;

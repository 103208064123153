// apmProvider.js
import React from "react";
import { init as initApm, ApmBase } from "@elastic/apm-rum";
import appConfig from "../config";

interface ApmContextType {
  apmInstance: ApmBase | null;
  traceId: string | null;
  apmTraceId: any;
}

const ApmContext = React.createContext<ApmContextType>({
  apmInstance: null,
  traceId: null,
  apmTraceId: null,
});

const url = new URL(appConfig.BASE_URL);
const baseUrl = url.origin;

const apm = initApm({
  serviceName: appConfig.ELASTIC_SERVICE_NAME,
  serverUrl: appConfig.ELASTIC_APM_SERVER_URL,
  environment: appConfig.ENV,
  distributedTracingOrigins: ["http://localhost:3010", baseUrl],
});

function apmTraceId(apm: ApmBase): any {
  const traceId =
    apm?.serviceFactory?.instances?.TransactionService?.currentTransaction
      ?.traceId || null;

  return traceId;
}

const apmContextValue: ApmContextType = {
  apmInstance: apm,
  traceId:
    apm?.serviceFactory?.instances?.TransactionService?.currentTransaction
      ?.traceId || null,
  apmTraceId,
};

export { apm, ApmContext, apmContextValue };

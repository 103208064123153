import React, { useContext } from "react";
import Styles from "../products.module.scss";
import sharedStyle from "../../../../../../shared/sharedStyle.module.scss";
import Button from "../../../../../../shared/button/button";
import { COMPRESSED } from "../../../../../../shared/buttonSize";
import {
  dangerButtonHoverStyle,
  dangerButtonStyle,
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../../../shared/buttonStyles";
import AddTestCredits from "../add-test-credits/addTestCredits";
import Checkbox from "../../../../../../shared/checkbox/checkbox";
import Loading from "../../../../../../shared/loading/loading";
import { ACCENTCOLOR } from "../../../../../../shared/colors";
import { monthName } from "../../../../../../utils/getMonth";
import { useParams } from "react-router-dom";
import DisableProductAuthentication from "../disable-product-authentication/disableProductAuthentication";
import { callPutApi } from "../../../../../../api/axios";
import { ToastContext } from "../../../../../../context/toastContext";
import { ERROR, SUCCESS } from "../../../../../../utils/toastType";
import { orgStatus } from "../../../../../../constants/orgStatus";
import { ApmContext } from "../../../../../../utils/apmProvider";

function ActiveProducts({
  org_status,
  activeProduct,
  isLoading,
  getUpdatedProducts,
  getUnsubscribedProducts,
  is_allowed_to_write,
  is_allowed_to_delete,
}: any) {
  const { id }: any = useParams();
  const dispatch = useContext(ToastContext);
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  const [testCreditLoading, setTestCreditLoading] = React.useState(false);
  const [toggleTestCreditModal, setToggleTestCreditModal] =
    React.useState(false);
  const [toggleDisableProductAuth, setToggleDisableAuthModal] =
    React.useState(false);
  const [selectedProductIds, setSelectedProductIds] = React.useState<String[]>(
    []
  );
  const loading = (
    <div
      style={{ height: "250px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Loading loadingColor={ACCENTCOLOR} />
    </div>
  );
  const emptyState = (
    <div
      style={{ height: "250px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <h5 className="mb-0">No Products Found</h5>
    </div>
  );
  function addSingleProduct(product: any) {
    if (selectedProductIds.includes(product.id)) {
      setSelectedProductIds(() =>
        selectedProductIds.filter((p) => {
          return p !== product.id;
        })
      );
      return;
    }
    setSelectedProductIds(() => [...selectedProductIds, product.id]);
    return;
  }
  async function onAddTestCredits(value: any) {
    setTestCreditLoading(true);
    try {
      const response: any = await callPutApi(
        `/organisations/${id}?action=add_test_credits`,
        { test_credits: value }
      );
      setTestCreditLoading(false);
      setToggleTestCreditModal(false);
      getUpdatedProducts();
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: SUCCESS,
          message: response.data.message,
        },
      });
    } catch (err: any) {
      setTestCreditLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message:
            err.response.data.err + " TraceId is \n" + apmTraceId(apmInstance),
          copy: apmTraceId(apmInstance),
        },
      });
    }
  }
  return (
    <div className={sharedStyle.card}>
      <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
        <div className="d-flex align-items-center">
          <p className={`mb-0 ${sharedStyle.cardHeader}`}>Active Products</p>
          <div className="px-3 ml-auto">
            {is_allowed_to_delete && selectedProductIds.length !== 0 ? (
              <Button
                size={COMPRESSED}
                style={dangerButtonStyle}
                hoveredStyle={dangerButtonHoverStyle}
                onClick={() => setToggleDisableAuthModal(true)}
                disabled={
                  org_status === orgStatus.DISABLED || !is_allowed_to_delete
                }
              >
                Disable
              </Button>
            ) : null}
          </div>
          {is_allowed_to_write && selectedProductIds.length === 0 ? (
            <div className="px-3 ml-auto">
              <Button
                size={COMPRESSED}
                style={primaryButtonStyle}
                hoveredStyle={primaryButtonHoverStyle}
                onClick={() => setToggleTestCreditModal(true)}
                disabled={
                  org_status === orgStatus.DISABLED || !is_allowed_to_write
                }
              >
                Add Test Credits
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      {isLoading ? (
        loading
      ) : (
        <div style={{ overflowX: "auto" }}>
          {activeProduct.length > 0 ? (
            <table style={{ width: "100%" }}>
              <thead className={sharedStyle.tableHeaderBack}>
                <tr>
                  {
                    <td className={Styles.checkbox_column}>
                      {selectedProductIds.length > 0 && (
                        <Checkbox
                          id="header"
                          disabled={!is_allowed_to_delete}
                          checked={selectedProductIds.length > 0}
                          onChange={() => {
                            setSelectedProductIds([]);
                          }}
                        />
                      )}
                    </td>
                  }
                  <th className={Styles.product_name_column}>
                    <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                      Product Name
                    </p>
                  </th>
                  <th className={Styles.activation_date}>
                    <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                      Activation Date
                    </p>
                  </th>
                  <th className={Styles.status}>
                    <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                      Status
                    </p>
                  </th>
                  <th
                    className={Styles.test_credits}
                    style={{ textAlign: "center" }}
                  >
                    <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                      Test Credits
                    </p>
                  </th>
                  <th
                    className={Styles.apps_linked}
                    style={{ textAlign: "center" }}
                  >
                    <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                      Apps Linked
                    </p>
                  </th>
                  <th
                    className={Styles.approved_by}
                    style={{ textAlign: "center" }}
                  >
                    <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                      Approved By
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody className={Styles.tableWrapper}>
                {activeProduct.map((product: any, index: number) => {
                  const date = new Date(Number(product.activation_date));
                  return (
                    <tr
                      key={index}
                      style={
                        index !== activeProduct.length - 1
                          ? { borderBottom: "1px solid #eceeef" }
                          : { borderBottom: 0 }
                      }
                    >
                      {
                        <td className={Styles.checkbox_column}>
                          <Checkbox
                            id={`id${index}`}
                            disabled={!is_allowed_to_delete}
                            checked={selectedProductIds.includes(product.id)}
                            onChange={() => addSingleProduct(product)}
                          />
                        </td>
                      }
                      <td className={Styles.product_name_column}>
                        <p className={`mb-0 ${Styles.productName}`}>
                          {product.name}
                        </p>
                      </td>
                      <td className={Styles.activation_date}>
                        <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                          {`${monthName(
                            date.getMonth()
                          )} ${date.getDate()}, ${date.getFullYear()}`}
                        </p>
                      </td>
                      <td className={Styles.status}>
                        <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                          {product.status}
                        </p>
                      </td>
                      <td
                        className={Styles.test_credits}
                        style={{ textAlign: "center" }}
                      >
                        <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                          {product.available_test_credits}
                        </p>
                      </td>
                      <td
                        className={Styles.apps_linked}
                        style={{ textAlign: "center" }}
                      >
                        <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                          {product.apps_linked}
                        </p>
                      </td>
                      <td
                        className={Styles.approved_by}
                        style={{ textAlign: "center" }}
                      >
                        <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                          {product.approved_by}
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            emptyState
          )}
        </div>
      )}
      {is_allowed_to_write && toggleTestCreditModal ? (
        <AddTestCredits
          isLoading={testCreditLoading}
          products={activeProduct.map((product: any) => ({
            name: product.name,
            arn: product.arn,
          }))}
          onCancel={() => setToggleTestCreditModal(false)}
          onUpdate={(value: any) => onAddTestCredits(value)}
        />
      ) : null}
      {is_allowed_to_delete && toggleDisableProductAuth ? (
        <DisableProductAuthentication
          id={id}
          selectedProductIds={selectedProductIds}
          onDisableProduct={() => {
            setToggleDisableAuthModal(false);
            setSelectedProductIds([]);
            getUpdatedProducts();
            getUnsubscribedProducts();
          }}
          onCancel={() => setToggleDisableAuthModal(false)}
        />
      ) : null}
    </div>
  );
}

export default ActiveProducts;

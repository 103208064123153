import React, { useContext, useEffect } from "react";
import Styles from "./home.module.scss";
import sharedStyle from "../../../shared/sharedStyle.module.scss";
import KeyValuePair from "../../../shared/key-value-pair/keyValuePair";
import ProductPendingApprovalCard from "./product-pending-approval-card/productPendingApprovalCard";
import AccountActivationPendingCard from "./account-activation-pending-card/accountActivationPendingCard";
import LowBalanceAlert from "./low-balance-alert/lowBalanceAlert";
import PaymentPending from "./payment-pending/paymentPending";
import RecentlyAssignedAccounts from "./recently-assigned-accounts/recentlyAssignedAccounts";
import UnassignedAccounts from "./unassigned-accounts/unassignedAccounts";
import { callGetApi } from "../../../api/axios";
import Loading from "../../../shared/loading/loading";
import { ACCENTCOLOR } from "../../../shared/colors";
import { ERROR } from "../../../utils/toastType";
import { ToastContext } from "../../../context/toastContext";
import { roleType } from "../../../constants/userRoles";
import { ApmContext } from "../../../utils/apmProvider";

function Home() {
  const currentUser = JSON.parse(localStorage.getItem("current_user") || "");
  const [summaryLoading, setSummaryLoading] = React.useState(false);
  const [accountSummary, setAccountSummary] = React.useState<any>();
  const [overallSummary, setOverallSummary] = React.useState<any>();
  const dispatch = useContext(ToastContext);
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  useEffect(() => {
    async function getSummary() {
      setSummaryLoading(true);
      try {
        const response: any = await callGetApi("/summary");
        const { account_summary, overall_summary } = response.data;
        setOverallSummary(overall_summary);
        setAccountSummary(account_summary);
        setSummaryLoading(false);
      } catch (error: any) {
        setSummaryLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message:
              error.response.data.err +
              " TraceId is \n" +
              apmTraceId(apmInstance),
          },
        });
      }
    }
    getSummary();
  }, [dispatch]);
  return (
    <div>
      <div className="container-fluid p-0">
        <div className="row">
          {/* My Account Summary card */}
          {currentUser.role === roleType.kam && (
            <div className="col-lg-4 p-3">
              <div className={sharedStyle.card}>
                <div
                  className={`d-flex align-items-center px-4 py-3 ${sharedStyle.cardHeaderBack}`}
                >
                  <p className={`mb-0 ${sharedStyle.cardHeader}`}>
                    My Account Summary
                  </p>
                </div>
                {summaryLoading ? (
                  <div
                    style={{ height: "250px" }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Loading loadingColor={ACCENTCOLOR} />
                  </div>
                ) : (
                  <div className="container-fluid py-3">
                    <div className="row">
                      <div
                        className="col-sm-6 d-flex align-items-center justify-content-center"
                        style={{ borderRight: "1px solid #f5f5f5" }}
                      >
                        <div>
                          <p className={`mb-0 ${Styles.totalAccountsValue}`}>
                            {accountSummary?.organisations.total_count ||
                            accountSummary?.organisations.total_count === 0
                              ? accountSummary.organisations.total_count
                              : "NA"}
                          </p>
                          <div className="py-1">
                            <p className={`mb-0 ${Styles.totalAccountsLabel}`}>
                              Total Accounts
                            </p>
                            <p className={`mb-0 ${Styles.helperText}`}>
                              {`${
                                accountSummary?.organisations
                                  .last_30_days_count ?? "NA"
                              } in last 30 days`}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 px-4">
                        <div className="row">
                          <div className="col-sm-12 col-6 ">
                            <KeyValuePair
                              className="p-3"
                              label="In Trial"
                              value={
                                accountSummary?.trial_organisations
                                  .total_count ||
                                accountSummary?.trial_organisations
                                  .total_count === 0
                                  ? accountSummary.trial_organisations
                                      .total_count
                                  : "NA"
                              }
                              helperText={`+${
                                accountSummary?.trial_organisations
                                  .last_30_days_count ?? "NA"
                              } in 30 days`}
                            />
                          </div>
                          <div className="col-sm-12 col-6 ">
                            <KeyValuePair
                              className="p-3"
                              label="In Production"
                              value={
                                accountSummary?.prod_organisations
                                  .total_count ||
                                accountSummary?.prod_organisations
                                  .total_count === 0
                                  ? accountSummary.prod_organisations
                                      .total_count
                                  : "NA"
                              }
                              helperText={`+${
                                accountSummary?.prod_organisations
                                  .last_30_days_count ?? "NA"
                              } in 30 days`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {/* Overall Summary card  */}
          <div
            className={`${
              currentUser.role === roleType.kam ? "col-lg-8" : "col-lg-12"
            } p-3`}
          >
            <div className={sharedStyle.card}>
              <div
                className={`d-flex align-items-center px-4 py-3 ${sharedStyle.cardHeaderBack}`}
              >
                <p className={`mb-0 ${sharedStyle.cardHeader}`}>
                  Overall Summary
                </p>
              </div>
              {summaryLoading ? (
                <div
                  style={{ height: "250px" }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <Loading loadingColor={ACCENTCOLOR} />
                </div>
              ) : (
                <div className="container-fluid py-3">
                  <div className="row">
                    <div
                      className="col-md-6"
                      style={{ borderRight: "1px solid #f5f5f5" }}
                    >
                      <div className="row py-2">
                        <div className="col-6">
                          <KeyValuePair
                            className="p-3"
                            label="Total Accounts"
                            value={
                              overallSummary?.organisations.total_count ||
                              overallSummary?.organisations.total_count === 0
                                ? overallSummary.organisations.total_count
                                : "NA"
                            }
                            helperText={`+${
                              overallSummary?.organisations
                                .last_30_days_count ?? "NA"
                            } in 30 days`}
                          />
                        </div>
                        <div className="col-6">
                          <KeyValuePair
                            className="p-3"
                            label="Unassigned"
                            value={
                              overallSummary?.unassigned_organisations
                                .total_count ||
                              overallSummary?.unassigned_organisations
                                .total_count === 0
                                ? overallSummary.unassigned_organisations
                                    .total_count
                                : "NA"
                            }
                            helperText={`+${
                              overallSummary?.unassigned_organisations
                                .last_30_days_count ?? "NA"
                            } in 30 days`}
                          />
                        </div>
                      </div>
                      <div className="row py-2">
                        <div className="col-6">
                          <KeyValuePair
                            className="p-3"
                            label="In Trial"
                            value={
                              overallSummary?.trial_organisations.total_count ||
                              overallSummary?.trial_organisations
                                .total_count === 0
                                ? overallSummary.trial_organisations.total_count
                                : "NA"
                            }
                            helperText={`+${
                              overallSummary?.trial_organisations
                                .last_30_days_count ?? "NA"
                            } in 30 days`}
                          />
                        </div>
                        <div className="col-6">
                          <KeyValuePair
                            className="p-3"
                            label="In Production"
                            value={
                              overallSummary?.prod_organisations.total_count ||
                              overallSummary?.prod_organisations.total_count ===
                                0
                                ? overallSummary.prod_organisations.total_count
                                : "NA"
                            }
                            helperText={`+${
                              overallSummary?.prod_organisations
                                .last_30_days_count ?? "NA"
                            } in 30 days`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="p-3">
                        <p className="mb-0">Graph will come here later</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          {/* Pending products approval card  */}
          <ProductPendingApprovalCard />
          {/* Pending account activation card  */}
          <AccountActivationPendingCard />
        </div>
        {/* Payment Pending Accounts  */}
        <PaymentPending />
        {/* Low Balance Alert Accounts  */}
        <LowBalanceAlert />
        {/* Unassigned Accounts */}
        <UnassignedAccounts />
        {/* Recently Assigned Accounts */}
        <RecentlyAssignedAccounts />
      </div>
    </div>
  );
}

export default Home;

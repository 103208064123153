import React, { useEffect, useState } from "react";
import Button from "../../../../shared/button/button";
import trackTaskStyle from "./trackTask.module.scss";
import { ReactComponent as Batchedit } from "../../../../assets/images/tpmedit.svg";
import { COMPRESSED, MEDIUM } from "../../../../shared/buttonSize";
import {
  blueHoverButtonStyle,
  borderButtonStyle,
  greyButtonStyle,
} from "../../../../shared/buttonStyles";
import PauseSvg from "../../../../assets/images/pause";
import StartSvg from "../../../../assets/images/start";
import RunningSvg from "../../../../assets/images/running";
import { ReactComponent as DeleteSvg } from "../../../../assets/images/deleteBatch.svg";
import { ReactComponent as CopySvg } from "../../../../assets/images/copyToClipBoard.svg";
import DownloadSvg from "../../../../assets/images/download";
import { ReactComponent as CancelSvg } from "../../../../assets/images/cancel.svg";
import moment from "moment";
import { callGetApi, callPostApi } from "../../../../api/axios";
import { isNumeric } from "../../../../utils/validation";
import {
  ACCENTCOLOR,
  DARKGREENCOLOR,
  DARKGREY,
  GREYCOLOR,
  PURPLE,
  REDCOLOR,
  WHITE,
  YELLOW,
} from "../../../../shared/colors";
import Loading from "../../../../shared/loading/loading";

interface reponseCodeCount {
  response_code?: string;
  count?: any;
  billable?: "Y" | "N";
}

interface TrackDataObject {
  batchId: string;
  batchName: string | null;
  batchState: string;
  batchType: string;
  created_at: string;
  destinationMode: string;
  downloadInitiated: string | null;
  downloadUrl: string | null;
  orgId: string;
  orgName: string | null;
  totalItems: number;
  totalProcessed: number;
  tpm: any;
  updated_at: string;
  uploadSource: string | null;
  userEmail: string | null;
  successCount: reponseCodeCount[];
}

interface ProgressData {
  orgIdAndName: any;
  taskData: TrackDataObject;
  callActionAPI: Function;
  userConfirmation: Function;
  successToast: Function;
  errorToast: Function;
  filter: string;
  activePageNumber: number;
  is_allowed_to_write: boolean;
}

const status = {
  PAUSED: "PAUSED",
  INITIALIZED: "INITIALIZED",
  RUNNING: "RUNNING",
  ERROR: "ERROR",
  FINISHED: "FINISHED",
  PROGRESS: "PROGRESS",
  STOP_INITIATED: "STOP_INITIATED",
};

function percentage(partialValue: number, totalValue: number) {
  const percent = (100 * partialValue) / totalValue;
  return percent.toFixed(2);
}

const ProgressCardCustomer = ({
  taskData,
  callActionAPI,
  userConfirmation,
  successToast,
  errorToast,
  filter,
  orgIdAndName,
  activePageNumber,
  is_allowed_to_write,
}: ProgressData) => {
  function getOrgNameById(org_id: string | null) {
    const org_name: any = orgIdAndName.find((item: any) => item._id === org_id);
    return org_name ? org_name.name : null;
  }

  function getProductNameByARN(batchType: unknown) {
    if (
      typeof batchType === "string" &&
      /^(IN)_[A-Za-z]+_[A-Za-z]+_[0-9A-Za-z]+$/.test(batchType) === true
    ) {
      return batchType.split("_").slice(2).join(" ");
    } else {
      return "NOT_KNOWN"; //fallback value in case batchType is not string
    }
  }
  const taskPercent = percentage(
    taskData?.totalProcessed || 0,
    taskData?.totalItems || 0
  );

  const [tmpReq, setTmpReq] = React.useState<string | undefined>();
  const [error, setError] = React.useState<string>("");
  const [tableOpen, setTableOpen] = React.useState<boolean>(false);
  const [editOpen, setEditOpen] = React.useState<boolean>(false);
  const [copiedText, setCopiedText] = React.useState<Boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingForTmp, setLoadingForTmp] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState<string>("Insurance");
  const [taskList, setTaskList] = React.useState<any>([]);

  // to get the list of the task.
  const getTaskDetailData = async () => {
    try {
      const response: any = await callGetApi(
        "productBatchService/getSuccessCount/" + taskData?.batchId || ""
      );
      if (response?.data?.successCount) {
        // setTaskList(response?.data?.successCount);
        const totalCount = response?.data?.successCount?.reduce(
          (acc: any, val: any) => acc + parseInt(val.count),
          0
        );
        const updatedTaskList = response?.data?.successCount.map(
          (task: any) => ({
            ...task,
            totalCount: totalCount,
          })
        );
        setTaskList(updatedTaskList);
      }

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tableOpen) {
      setLoading(true);
      getTaskDetailData();
    }
    // eslint-disable-next-line
  }, [tableOpen]);

  useEffect(() => {
    setTaskList([]);
    setTableOpen(false);
  }, [activePageNumber]);

  useEffect(() => {
    setTmpReq(taskData.tpm);
  }, [taskData]);

  const useDynamicHover = () => {
    const [hoveredItems, setHoveredItems] = useState<{
      [key: string]: boolean;
    }>({});

    const handleMouseEnter = (key: string) => {
      setHoveredItems((prev) => ({ ...prev, [key]: true }));
    };

    const handleMouseLeave = (key: string) => {
      setHoveredItems((prev) => ({ ...prev, [key]: false }));
    };

    return { hoveredItems, handleMouseEnter, handleMouseLeave };
  };

  const { hoveredItems, handleMouseEnter, handleMouseLeave } =
    useDynamicHover();

  const startPauseBtn = () => {
    const isStart =
      is_allowed_to_write &&
      (taskData.batchState === status.PAUSED ||
        taskData.batchState === status.INITIALIZED);

    const isRunning =
      is_allowed_to_write && taskData.batchState === status.RUNNING;

    const disable =
      is_allowed_to_write &&
      (taskData.batchState === status.RUNNING ||
        taskData.batchState === status.INITIALIZED ||
        taskData.batchState === status.PAUSED);

    return (
      <div
        className={`${trackTaskStyle.actionButton} ${trackTaskStyle.startBtn} ${
          !disable ? trackTaskStyle.disabled : ""
        }`}
        onClick={() => {
          if (!is_allowed_to_write) return;
          userConfirmation(
            taskData.batchId,
            isStart ? "START_TASK" : "PAUSE_TASK"
          );
        }}
      >
        <div className="d-flex align-items-center h-100 justify-content-center">
          {isStart ? (
            <>
              {" "}
              <div
                className={trackTaskStyle.startbtn}
                onMouseEnter={() => handleMouseEnter("isStart")}
                onMouseLeave={() => handleMouseLeave("isStart")}
              >
                <StartSvg
                  fillBackgroundColor={
                    hoveredItems["isStart"] ? DARKGREENCOLOR : WHITE
                  }
                  fillInnerColor={
                    hoveredItems["isStart"] ? WHITE : DARKGREENCOLOR
                  }
                  border={!hoveredItems["isStart"] ? DARKGREENCOLOR : ""}
                />
              </div>
            </>
          ) : isRunning ? (
            <>
              {" "}
              <div>
                <div
                  className={trackTaskStyle.pauseButton}
                  onMouseEnter={() => handleMouseEnter("isRunning")}
                  onMouseLeave={() => handleMouseLeave("isRunning")}
                >
                  <RunningSvg
                    fillBackgroundColor={
                      hoveredItems["isRunning"] ? DARKGREENCOLOR : WHITE
                    }
                    fillInnerColor={
                      hoveredItems["isRunning"] ? WHITE : DARKGREENCOLOR
                    }
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className={trackTaskStyle.startbtn}>
                <StartSvg
                  fillBackgroundColor={GREYCOLOR}
                  fillInnerColor={DARKGREY}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const stopBtn = () => {
    const disable =
      is_allowed_to_write &&
      (taskData.batchState === status.RUNNING ||
        taskData.batchState === status.PAUSED);

    return (
      <div
        className={`${trackTaskStyle.actionButton} ${trackTaskStyle.stopBtn} ${
          !disable ? trackTaskStyle.disabled : ""
        }`}
        onClick={() => userConfirmation(taskData.batchId, "STOP_TASK")}
      >
        <div
          className="d-flex align-items-center h-100 justify-content-center"
          onMouseEnter={() => handleMouseEnter("isPause")}
          onMouseLeave={() => handleMouseLeave("isPause")}
        >
          <PauseSvg
            fillBackgroundColor={
              hoveredItems["isPause"] ? REDCOLOR : !disable ? GREYCOLOR : WHITE
            }
            InnerColor={
              hoveredItems["isPause"] ? WHITE : !disable ? DARKGREY : REDCOLOR
            }
            border={
              hoveredItems["isPause"] ? WHITE : !disable ? WHITE : REDCOLOR
            }
          />
        </div>
      </div>
    );
  };

  const deleteBtn = () => {
    let disable =
      taskData.batchState === status.INITIALIZED ||
      taskData.batchState === status.PAUSED ||
      taskData.batchState === status.FINISHED ||
      taskData.batchState === status.ERROR;

    if (
      taskData.batchState === status.FINISHED &&
      taskData?.downloadInitiated &&
      !taskData?.downloadUrl
    ) {
      disable = false;
    }

    return (
      <div className={trackTaskStyle.deleteButtonDiv}>
        <div></div>
        <div
          className={trackTaskStyle.deleteButton}
          onClick={() => userConfirmation(taskData.batchId, "DELETE_TASK")}
        >
          <div className={trackTaskStyle.deleteButton2}>Delete Batch</div>
          <DeleteSvg />
        </div>
      </div>
    );
  };

  const handleChange = (e: any) => {
    setSelectedValue(e.target.value);
  };

  /* This is just a design For rc and Non Rc batch for Approving
  the batch for Insurance and finance */

  const ApproveBatchForRc = (taskId: string) => {
    return (
      <>
        <div className={trackTaskStyle.progressAdjustment}>
          <div
            style={{
              display: "flex",
              gap: "35px",
            }}
          >
            <div style={{ marginTop: "10px" }}>
              <input
                type="radio"
                name="flexRadio"
                value="Insurance"
                style={{ cursor: "pointer" }}
                checked={selectedValue === "Insurance"}
                onChange={handleChange}
              />
              <label className={trackTaskStyle.label}>Insurance</label>
            </div>
            <div style={{ marginTop: "10px" }}>
              <input
                type="radio"
                name="flexRadio"
                style={{ cursor: "pointer" }}
                value="Finance"
                checked={selectedValue === "Finance"}
                onChange={handleChange}
              />
              <label className={trackTaskStyle.label}>Finance</label>
            </div>
          </div>
        </div>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ marginLeft: "40px" }}
        >
          <Button
            size={MEDIUM}
            style={blueHoverButtonStyle}
            hoveredStyle={blueHoverButtonStyle}
          >
            Approve Batch
          </Button>
        </div>
      </>
    );
  };

  const allActionDisabled = ["CREATED"].includes(taskData.batchState);

  // To validate the TPM Req
  function tpmValidation() {
    if (!String(tmpReq) || !String(tmpReq?.length)) {
      setError("TPM Request cannot be empty");
      return false;
    }
    if (Number(tmpReq) < 0) {
      setError("TPM Request cannot be negative");
      return false;
    }
    if (Number(tmpReq) === 0) {
      setError("TPM Request cannot be zero");
      return false;
    }
    // if (
    //   (taskData.batchType === "IN_VEHICLE_RC_REVERSE" && Number(tmpReq) > 20) ||
    //   Number(tmpReq) < 1
    // ) {
    //   setError(
    //     "TPM Request cannot be more than 20 and less than 1 for Rc Reverse"
    //   );
    //   return false;
    // }

    if (Number(tmpReq) > 1000 || Number(tmpReq) < 1) {
      setError("TPM Request cannot be more than 1000 and less than 1");
      return false;
    }
    if (!isNumeric(String(tmpReq))) {
      setError("No decimal values allowed");
      return false;
    }
    return true;
  }

  async function copyBatchId(batchId: string) {
    try {
      if (batchId) {
        await navigator.clipboard.writeText(batchId);
        setCopiedText(true);
      }
      // setCopiedText(true);
    } catch (error) {
      console.error(error);
    }
  }

  // To update the batch task.
  const updateBatchTask = async () => {
    const tmpRequest = [tpmValidation()].every(Boolean);
    if (!tmpRequest) {
      errorToast(error);
      return;
    }

    if (String(taskData?.tpm) === String(tmpReq)) return;

    try {
      setLoadingForTmp(true);
      const response: any = await callPostApi(
        "productBatchService/updateBatch/" + taskData?.batchId || "",
        {
          tpm: Number(tmpReq),
        }
      );
      if (response) {
        if (response.success === true) {
          taskData.tpm = tmpReq;
          successToast(response.message || "TPM Request Updated");
          setEditOpen(false);
        } else {
          errorToast(response.message);
        }
      }
      setLoadingForTmp(false);
    } catch (err: any) {
      errorToast("Error in updating TPM Request");
      setLoadingForTmp(false);
    }
  };

  return (
    <div
      className={` ${trackTaskStyle.card}`}
      style={{
        borderLeft:
          taskData?.batchState === "ERROR"
            ? `6px solid ${REDCOLOR}`
            : taskData?.batchState === "FINISHED"
            ? `6px solid ${DARKGREENCOLOR}`
            : taskData?.batchState === "PROGRESS"
            ? `6px solid ${PURPLE}`
            : `6px solid ${YELLOW}`,
      }}
    >
      <div
        className={`d-flex justify-content-between`}
        style={{ marginLeft: "25px" }}
      >
        <div className="d-flex">
          <div className={trackTaskStyle.progressBlock + " pl-1"}>
            <div className={trackTaskStyle.orgName}>
              Customer:{"  "}
              {taskData?.orgName
                ? taskData?.orgName
                : "" || getOrgNameById(taskData.orgId)}
            </div>
            <div className={trackTaskStyle.batchName}>
              {taskData.batchName ? taskData.batchName : "NA"}
            </div>
            <div className="d-flex mt-1">
              <div
                className="mr-2"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Created At:{" "}
              </div>
              <div className={trackTaskStyle.value}>
                {moment(taskData?.created_at).format("DD/MM/YYYY, HH:mm")}
              </div>
            </div>
            <div className="d-flex mt-1">
              <div
                className="mr-2"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Updated At:{" "}
              </div>
              <div className={trackTaskStyle.value}>
                {moment(taskData?.updated_at).format("DD/MM/YYYY, HH:mm")}
              </div>
            </div>
            <div className="d-flex mt-1">
              <div
                className="mr-2"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Batch Size:{" "}
              </div>
              <div className={trackTaskStyle.value}>{taskData?.totalItems}</div>
            </div>
            <div className="d-flex mt-1" style={{ width: "100%" }}>
              <div
                className="mr-2"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Batch Id:{" "}
              </div>

              <p
                className={trackTaskStyle.value}
                style={{ display: "flex", gap: "6px" }}
              >
                {taskData.batchId}
                <p
                  className={trackTaskStyle.copyButton}
                  style={{
                    height: "30px",
                    cursor: "pointer",
                  }}
                >
                  <CopySvg onClick={() => copyBatchId(taskData.batchId)} />
                  <p className={trackTaskStyle.copyText}>
                    {copiedText ? "Copied" : "Copy text"}
                  </p>
                </p>
              </p>
            </div>
          </div>

          <div className={trackTaskStyle.borderGap} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              gap: "4px",
              marginTop: "50px",
            }}
          >
            <div
              className="mr-2"
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "600",
                fontSize: "12px",
              }}
            >
              {getProductNameByARN(taskData?.batchType)}
            </div>

            <div
              className="mr-2"
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              Progress:
            </div>
            <div className={trackTaskStyle.progressAdjustment}>
              <progress
                style={{ width: "100%" }}
                id="file"
                value={taskData?.totalProcessed}
                max={taskData?.totalItems}
              ></progress>
              <span className="ml-2">{taskPercent}%</span>
            </div>
            {taskData?.batchState === status.ERROR ? (
              <>
                <div className={trackTaskStyle.errorBox}>ERROR</div>
              </>
            ) : taskData?.batchState === status.FINISHED ? (
              <div className={trackTaskStyle.finishedBox}>FINISHED</div>
            ) : taskData?.batchState === status.PROGRESS ? (
              <div className={trackTaskStyle.progressBox}>PROGRESS</div>
            ) : (
              <div className={trackTaskStyle.pausedBox}>
                {taskData?.batchState}
              </div>
            )}
          </div>

          <div className={trackTaskStyle.borderGap2} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
              marginTop: "50px",
            }}
          >
            <div className="d-flex align-items-center">
              <div className="d-flex " style={{ marginRight: "10px" }}>
                TPM:
              </div>
              <div
                className={trackTaskStyle.tpminput}
                style={{
                  pointerEvents:
                    taskData.batchState === status.FINISHED ||
                    taskData.batchState === status.ERROR ||
                    taskData.batchState === status.PROGRESS ||
                    taskData.batchState === status.STOP_INITIATED
                      ? "none"
                      : "auto",
                  opacity:
                    taskData.batchState === status.FINISHED ||
                    taskData.batchState === status.ERROR ||
                    taskData.batchState === status.PROGRESS ||
                    taskData.batchState === status.STOP_INITIATED
                      ? 0.5
                      : 1,
                }}
              >
                <input
                  type="text"
                  style={{
                    outline: "none",
                    width: "80px",
                    overflow: "scroll",
                    backgroundColor: "#fff",
                    paddingLeft: "20px",
                  }}
                  onChange={(e: any) => {
                    const re = /^[0-9\b]+$/;

                    if (e.target.value === "" || re.test(e.target.value)) {
                      setTmpReq(e.target.value);
                    }

                    setError("");
                  }}
                  value={tmpReq}
                  onBlur={tpmValidation}
                  disabled={
                    is_allowed_to_write && editOpen
                      ? false
                      : true ||
                        taskData.batchState === status.FINISHED ||
                        taskData.batchState === status.ERROR ||
                        taskData.batchState === status.PROGRESS ||
                        taskData.batchState === status.STOP_INITIATED
                  }
                  maxLength={4}
                />
                {!editOpen ? (
                  <Batchedit
                    style={{
                      position: "absolute",
                      right: "8px",
                      top: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setEditOpen(!editOpen)}
                  />
                ) : (
                  <CancelSvg
                    style={{
                      position: "absolute",
                      right: "8px",
                      top: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setEditOpen(!editOpen)}
                  />
                )}
              </div>
            </div>

            <Button
              size={COMPRESSED}
              style={greyButtonStyle}
              disabled={
                !is_allowed_to_write ||
                taskData.batchState === status.PROGRESS ||
                taskData.batchState === status.FINISHED ||
                taskData.batchState === status.ERROR ||
                taskData.batchState === status.STOP_INITIATED
              }
              hoveredStyle={blueHoverButtonStyle}
              onClick={updateBatchTask}
              isLoading={loadingForTmp}
            >
              Submit
            </Button>
          </div>
          <div className={trackTaskStyle.borderGap2} />
        </div>
        <div
          className={
            trackTaskStyle.buttonsBlock +
            " " +
            (allActionDisabled ? trackTaskStyle.disabled : "")
          }
        >
          {startPauseBtn()}
          {stopBtn()}

          <div
            className={`${trackTaskStyle.actionButton} ${trackTaskStyle.downloadBtn}`}
          >
            {filter === "Admin" ? (
              <div className="d-flex align-items-center h-100 justify-content-center">
                {taskData?.downloadUrl !== null &&
                taskData?.downloadUrl.length ? (
                  <a
                    href={taskData?.downloadUrl || ""}
                    className="w-60 d-flex justify-content-center"
                    download
                  >
                    <Button
                      size={MEDIUM}
                      style={borderButtonStyle}
                      disabled={
                        !is_allowed_to_write ||
                        taskData.batchState !== "FINISHED"
                      }
                      hoveredStyle={blueHoverButtonStyle}
                      onMouseEnter={() => handleMouseEnter("isDownload")}
                      onMouseLeave={() => handleMouseLeave("isDownload")}
                    >
                      <span className="mr-2 mt-3">Download</span>
                      <DownloadSvg
                        fillBackgroundColor={
                          hoveredItems["isDownload"] ? "#fff" : "#3272EC"
                        }
                      />
                    </Button>
                  </a>
                ) : (
                  <Button
                    size={MEDIUM}
                    style={borderButtonStyle}
                    disabled={
                      taskData.batchState !== "FINISHED" ||
                      taskData?.downloadInitiated
                    }
                    hoveredStyle={borderButtonStyle}
                  >
                    <span className="mr-2 mt-3">Download</span>
                    <DownloadSvg fillBackgroundColor={"#3272EC"} />
                  </Button>
                )}
              </div>
            ) : null}
          </div>

          {/* {deleteBtn()} */}
          <div
            className={`${
              trackTaskStyle.actionButton
            } d-flex justify-content-center ${
              tableOpen ? trackTaskStyle.roatate90 : ""
            }`}
            onClick={() => setTableOpen(!tableOpen)}
          >
            <p>{">"}</p>
          </div>
          <div className="m-3 p-2"></div>
        </div>
      </div>

      {tableOpen ? (
        <>
          {loading ? (
            <div
              style={{ height: "100px" }}
              className="d-flex align-items-center justify-content-center"
            >
              <Loading loadingColor={ACCENTCOLOR}></Loading>
            </div>
          ) : (
            <div
              className={trackTaskStyle.taskTableDiv}
              onClick={getTaskDetailData}
            >
              <p className={trackTaskStyle.taskTableTitle}> Batch Details </p>

              <div className="px-3 w-50 ">
                <table className="w-100">
                  <tr className={trackTaskStyle.taskCountTable}>
                    <th
                      className={trackTaskStyle.tableHead}
                      style={{ border: "none" }}
                    >
                      Response Code
                    </th>
                    <th
                      className={trackTaskStyle.tableHead}
                      style={{ border: "none" }}
                    ></th>
                    <th
                      className={trackTaskStyle.tableHead}
                      style={{ border: "none" }}
                    >
                      Count
                    </th>
                  </tr>

                  {taskList && taskList?.length ? (
                    <>
                      {taskList?.map((item: any, index: number) => (
                        <tr
                          key={index}
                          className={trackTaskStyle.taskCountTableValue}
                        >
                          <th
                            className={trackTaskStyle.tableHead}
                            style={{ border: "none", color: "#969696" }}
                          >
                            {item.response_code}
                          </th>
                          <th
                            className={trackTaskStyle.tableHead}
                            style={{
                              border: "none",
                              color: "#969696",
                              paddingLeft: "70px",
                            }}
                          ></th>
                          <th
                            className={trackTaskStyle.tableHead}
                            style={{ border: "none", color: "#969696" }}
                          >
                            {item.count}
                          </th>
                        </tr>
                      ))}
                      <tr className={trackTaskStyle.taskCountTableValue}>
                        <th
                          className={trackTaskStyle.tableHead}
                          style={{ border: "none", color: "black" }}
                        >
                          {}
                        </th>
                        <th
                          className={trackTaskStyle.tableHead}
                          style={{
                            border: "none",
                            color: "black",
                            paddingLeft: "75px",
                          }}
                        >
                          Total
                        </th>
                        <th
                          className={trackTaskStyle.tableHead}
                          style={{ border: "none", color: "black" }}
                        >
                          {taskList && taskList[0].totalCount}
                        </th>
                      </tr>
                    </>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center pt-4">
                      No Data available
                    </div>
                  )}
                </table>
              </div>
              {/* {deleteBtn()} */}
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default ProgressCardCustomer;

import React, { useState, useEffect, useContext } from "react";
import { callGetApi, callPutApi } from "../../../../../api/axios";
import { roleType } from "../../../../../constants/userRoles";
import { ToastContext } from "../../../../../context/toastContext";
import { ACCENTCOLOR } from "../../../../../shared/colors";
import Loading from "../../../../../shared/loading/loading";
import { ERROR } from "../../../../../utils/toastType";
import popUpStyles from "../../../../../shared/popup.module.scss";
import sharedStyle from "../../../../../shared/sharedStyle.module.scss";
import InputDropdown from "../../../../../shared/input-dropdown/inputDropdown";
import KeyValuePair from "../../../../../shared/key-value-pair/keyValuePair";
import Button from "../../../../../shared/button/button";
import { MEDIUM } from "../../../../../shared/buttonSize";
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  successButtonHoverStyle,
  successButtonStyle,
} from "../../../../../shared/buttonStyles";
import { ApmContext } from "../../../../../utils/apmProvider";

interface BmDetailsType {
  bm_name: string;
  bm_email: string;
  bm_phone: string;
  id: string;
}

function ModifyBusinessManager({
  accountInfo = {},
  action,
  onCancel,
  onUpdate,
  org_id,
}: any) {
  const [tempBmDetails, setTempBmDetails] = useState<BmDetailsType>({
    bm_name: accountInfo?.bm?.bm_name,
    bm_email: accountInfo?.bm?.bm_email,
    bm_phone: accountInfo?.bm?.bm_phone,
    //id: accountInfo?.bm?.id
    id: accountInfo?.bm?.bm_id,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [listOfBm, setListOfBm] = useState<BmDetailsType[]>([]);
  //const [listOfBm, setListOfBm] = useState<any[]>([]);
  const [isBmModified, setIsBmModified] = useState<boolean>(false);
  const dispatch = useContext(ToastContext);

  useEffect(() => {
    async function getAllBusinessManagers() {
      setIsLoading(true);
      try {
        const { data }: any = await callGetApi("/kam");
        const bmList: BmDetailsType[] = data
          .filter(
            (bm: any) =>
              bm.status === "ACTIVE" &&
              [roleType.business_manager, roleType.admin].includes(bm.role)
          )
          .map((bm: any, index: number) => {
            return {
              bm_name: bm.name ? bm.name : `no_name_${index}`,
              bm_email: bm.email ? bm.email : `no_email_${index}`,
              bm_phone: bm.phone ? bm.phone : `no_phone_${index}`,
              id: bm._id,
            };
          });
        setListOfBm(bmList);
        setIsLoading(false);
      } catch (err: any) {
        setIsLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message:
              err.response.data.err +
              " TraceId is \n" +
              apmTraceId(apmInstance),
            copy: apmTraceId(apmInstance),
          },
        });
      }
    }
    getAllBusinessManagers();
  }, [dispatch]);

  async function updateBm() {
    setButtonLoading(true);
    try {
      const response: any = await callPutApi(
        `/organisations/${org_id}?action=update_bm`,
        {
          bm_id: tempBmDetails.id,
        }
      );
      const { business_manager } = response.data;
      setButtonLoading(false);
      onUpdate(business_manager);
    } catch (err: any) {
      setButtonLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message:
            err.response.data.err + " TraceId is \n" + apmTraceId(apmInstance),
          copy: apmTraceId(apmInstance),
        },
      });
    }
  }

  const loading: JSX.Element = (
    <div
      style={{ height: "230px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Loading loadingColor={ACCENTCOLOR} />
    </div>
  );

  return (
    <div
      className={`d-flex align-items-center justify-content-center ${popUpStyles.overlay}`}
    >
      <div className={`${popUpStyles.popUpContent}`} style={{ width: "500px" }}>
        {isLoading ? (
          loading
        ) : (
          <div>
            <div className={`p-4 ${sharedStyle.cardHeaderBack}`}>
              <p className={`mb-0 ${sharedStyle.cardHeader}`}>
                {action} Business Manager
              </p>
            </div>
            <div className="px-4 pt-4 mx-2">
              <div className="py-2">
                <InputDropdown
                  labelname="Business Manager"
                  optionsArray={listOfBm.map((bm: any) => bm.bm_name)}
                  click={(value: string, index: number) => {
                    setIsBmModified(true);
                    /* const selectedBm = listOfBm.find(
                      (bm: any) => value === bm.bm_name
                    ); */
                    const selectedBm =
                      listOfBm[index].bm_name === value
                        ? listOfBm[index]
                        : listOfBm.find((bm: any) => value === bm.bm_name);
                    setTempBmDetails((bmDetails: any) => ({
                      ...bmDetails,
                      ...selectedBm,
                    }));
                  }}
                  defaultValue={tempBmDetails.bm_name ?? "name of the manager"}
                  no_shadow="true"
                />
              </div>
              <div className="py-3 container-fluid">
                <div className="row no-gutters">
                  <div className="col-sm-6">
                    <KeyValuePair
                      className="py-2"
                      label="Contact"
                      value={tempBmDetails.bm_phone ?? "NA"}
                    />
                  </div>
                  <div className="col-sm-6">
                    <KeyValuePair
                      className="py-2"
                      label="Email"
                      value={tempBmDetails.bm_email ?? "NA"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="py-4 my-2 d-flex align-items-center justify-content-center"
              style={{ borderTop: "1px solid #ECEEEF" }}
            >
              <div className="px-2">
                <Button
                  disabled={buttonLoading}
                  size={MEDIUM}
                  style={cancelButtonStyle}
                  hoveredStyle={cancelButtonHoverStyle}
                  onClick={() => onCancel()}
                >
                  Cancel
                </Button>
              </div>
              <div className="px-2">
                <Button
                  size={MEDIUM}
                  disabled={buttonLoading || !isBmModified}
                  isLoading={buttonLoading}
                  style={successButtonStyle}
                  hoveredStyle={successButtonHoverStyle}
                  onClick={() => updateBm()}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ModifyBusinessManager;

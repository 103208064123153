import React from "react";
import Styles from "./switch.module.scss";

function Switch({
  positiveLabel,
  negativeLabel,
  isDisabled = null,
  miniLabel = false,
  disableDark = false,
  switchColor,
  headerStyle,
  ...props
}: any) {
  return (
    <div className="d-flex align-items-center">
      <div className="pr-2">
        <p
          className={`m-0 ${
            miniLabel ? Styles.formLabelMini : Styles.switch_text
          }`}
        >
          {negativeLabel}
        </p>
      </div>
      <div className="pr-2">
        <div className={Styles.switch__container}>
          <input
            className={`${Styles.switch} ${switchColor} ${
              Styles.switch__shadow
            } ${disableDark ? Styles.darkBackgroundColor : null}`}
            type="checkbox"
            disabled={isDisabled}
            {...props}
          />
          <label
            htmlFor={props.id}
            className="mb-0"
            style={
              isDisabled && {
                cursor: "no-drop",
                backgroundColor: `${disableDark ? "#E0E0E0" : "#1c8cdb"}`,
              }
            }
          ></label>
        </div>
      </div>
      <div className="pr-2">
        <p
          className={`m-0 ${
            miniLabel ? Styles.formLabelMini : Styles.switch_text
          }`}
        >
          {positiveLabel}
        </p>
      </div>
    </div>
  );
}

export default Switch;

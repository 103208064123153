import React, { useContext, useEffect } from "react";
import sharedStyle from "../../../../shared/sharedStyle.module.scss";
import popUpStyles from "../../../../shared/popup.module.scss";
import Styles from "./editUserModal.module.scss";
import Button from "../../../../shared/button/button";
import { MEDIUM } from "../../../../shared/buttonSize";
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../shared/buttonStyles";
import RadioButton from "../../../../shared/radio-button/radioButton";
import InputDropdown from "../../../../shared/input-dropdown/inputDropdown";
import { callGetApi, callPutApi } from "../../../../api/axios";
import { ERROR } from "../../../../utils/toastType";
import { ToastContext } from "../../../../context/toastContext";
import Input from "../../../../shared/input/input";
import {
  checkIsEmpty,
  isValidEmailFirstChar,
  isValidName,
  isValidPhoneNumber,
} from "../../../../utils/validation";
import ErrorMessage from "../../../../shared/error-message/errorMessage";
import Loading from "../../../../shared/loading/loading";
import { ACCENTCOLOR } from "../../../../shared/colors";
import { ApmContext } from "../../../../utils/apmProvider";

function EditUserModal({ user, onCancel, onUpdate, is_allowed_to_write }: any) {
  const statusTypes = {
    active: "ACTIVE",
    disabled: "DISABLED",
  };
  const [userName, setUserName] = React.useState(user.name);
  const [userEmail, setUserEmail] = React.useState(user.email);
  const [userPhone, setUserPhone] = React.useState(user.phone.substr(3));
  const [error, setError] = React.useState({
    name: "",
    email: "",
    phone: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [listOfRoles, setListOfRoles] = React.useState([]);
  const [status, setStatus] = React.useState(user.status);
  const [role, setRole] = React.useState(user.role);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const dispatch = useContext(ToastContext);
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  const emailRegExp = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
  async function updateKamStatus() {
    try {
      await callPutApi(`/kam/${user.id}?action=update_kam_status`, {
        status: status === statusTypes.active ? "enabled" : "disabled",
      });
      return true;
    } catch (err: any) {
      setStatus(user.status);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: `This account cannot be disabled as it assigned to ${err.response.data.err} Accounts`,
        },
      });
      return false;
    }
  }
  async function updateKamRole() {
    try {
      await callPutApi(`/kam/${user.id}?action=update_kam_role`, {
        role: role,
      });
      return true;
    } catch (err: any) {
      setRole(user.role);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message:
            err.response.data.err + " TraceId is \n" + apmTraceId(apmInstance),
          copy: apmTraceId(apmInstance),
        },
      });
      return false;
    }
  }
  async function updateUserInfo() {
    try {
      await callPutApi(`/kam/${user.id}?action=update_kam_data`, {
        name: userName.trim(),
        email: userEmail.trim(),
        phone: `+91${userPhone.trim()}`,
      });
      return true;
    } catch (err: any) {
      setUserName(user.name);
      setUserEmail(user.email);
      setUserPhone(user.phone.substr(3));
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message:
            err.response.data.err + " TraceId is \n" + apmTraceId(apmInstance),
          copy: apmTraceId(apmInstance),
        },
      });
      return false;
    }
  }
  async function updateUser() {
    if (error.name !== "" || error.email !== "" || error.phone !== "") {
      return;
    }
    setButtonLoading(true);
    const listOfSuccessUpdates = [];
    if (
      user.name !== userName ||
      user.email !== userEmail ||
      user.phone.substr(3) !== userPhone
    ) {
      const data = await updateUserInfo();
      listOfSuccessUpdates.push(data);
    }
    if (user.status !== status) {
      const data = await updateKamStatus();
      listOfSuccessUpdates.push(data);
    }
    if (user.role !== role) {
      const data = await updateKamRole();
      listOfSuccessUpdates.push(data);
    }
    setButtonLoading(false);
    if (listOfSuccessUpdates.every(Boolean)) {
      onUpdate(
        user.id,
        status,
        role,
        userName.trim(),
        userEmail.trim(),
        `+91${userPhone.trim()}`
      );
    }
  }
  function checkName() {
    if (checkIsEmpty(userName)) {
      setError((error) => ({
        ...error,
        name: "Name cannot be empty",
      }));
      return false;
    }
    if (isValidName(userName, false)) {
      setError((error) => ({
        ...error,
        name: "Enter a valid name",
      }));
      return false;
    }
    return true;
  }
  function checkEmail() {
    if (checkIsEmpty(userEmail)) {
      setError((error) => ({
        ...error,
        email: "Email cannot be empty",
      }));
      return false;
    }
    if (!emailRegExp.test(userEmail)) {
      setError((error) => ({
        ...error,
        email: "Enter a valid email",
      }));
      return false;
    }
    if (isValidEmailFirstChar(userEmail)) {
      setError((error) => ({
        ...error,
        email: "Email cannot start with a number",
      }));
      return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, domain] = userEmail.split("@");
    if (domain !== "zoop.one") {
      setError((error) => ({
        ...error,
        email: "Only Zoop emails are allowed",
      }));
      return false;
    }
    return true;
  }
  function checkPhoneNumber() {
    if (checkIsEmpty(userPhone)) {
      setError((error) => ({
        ...error,
        phone: "Phone number cannot be empty",
      }));
      return false;
    }
    if (!isValidPhoneNumber(userPhone)) {
      setError((error) => ({
        ...error,
        phone: "Enter a valid Phone number",
      }));
      return false;
    }
    if (userPhone[0] === "0") {
      setError((error) => ({
        ...error,
        phone: "Exclude 0 from beginning",
      }));
      return false;
    }
    if (userPhone.length < 10) {
      setError((error) => ({
        ...error,
        phone: "Phone number should be of 10 digit!",
      }));
      return false;
    }
    if (userPhone.length > 10) {
      setError((error) => ({
        ...error,
        phone: "Phone number should be of 10 digit only!",
      }));
      return false;
    }
    return true;
  }
  function isButtonDisabled() {
    return (
      (user.status === status &&
        user.role === role &&
        user.name === userName.trim() &&
        user.email === userEmail.trim() &&
        user.phone.substr(3) === userPhone.trim()) ||
      error.name !== "" ||
      error.email !== "" ||
      error.phone !== "" ||
      buttonLoading
    );
  }
  async function getAllRoles() {
    setLoading(true);
    try {
      const { err, data }: any = await callGetApi("/roles");

      if (err) {
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: "Failed to fetch all permissions!",
          },
        });
        return;
      }
      setListOfRoles(data?.map((role: any) => role.role) || []);
    } catch (err: any) {
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: "Failed to fetch all permissions!",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAllRoles();
  }, []);

  return (
    <div
      className={`d-flex align-items-center justify-content-center ${popUpStyles.overlay}`}
    >
      <div className={`${popUpStyles.popUpContent} ${Styles.card}`}>
        <div className={`p-4 ${sharedStyle.cardHeaderBack}`}>
          <p className={`mb-0 ${sharedStyle.cardHeader}`}>Edit User</p>
        </div>
        <div className="p-4 mx-2">
          {loading ? (
            <div className="d-flex align-items-center justify-content-center">
              <Loading loadingColor={ACCENTCOLOR} />
            </div>
          ) : (
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-6 pb-3">
                  <Input
                    no_shadow="true"
                    type="text"
                    name="user_name"
                    id="user_name"
                    autoComplete="off"
                    value={userName}
                    hasError={error.name}
                    placeholder="Enter Name"
                    labelname="Name"
                    maxLength={25}
                    onChange={(event: any) => {
                      setUserName(event.target.value);
                      setError((error) => ({
                        ...error,
                        name: "",
                      }));
                    }}
                    onBlur={checkName}
                  />
                  {error.name && <ErrorMessage>{error.name}</ErrorMessage>}
                </div>
                <div className="col-md-6 pb-3">
                  <Input
                    no_shadow="true"
                    type="email"
                    name="user_email"
                    id="user_email"
                    autoComplete="off"
                    value={userEmail}
                    hasError={error.email}
                    placeholder="Enter user's Email"
                    labelname="Email"
                    onChange={(event: any) => {
                      setUserEmail(event.target.value);
                      setError((error) => ({
                        ...error,
                        email: "",
                      }));
                    }}
                    onBlur={checkEmail}
                  />
                  {error.email && <ErrorMessage>{error.email}</ErrorMessage>}
                </div>
                <div className="col-md-6 pb-3">
                  <Input
                    no_shadow="true"
                    type="text"
                    name="user_phone"
                    id="user_phone"
                    autoComplete="off"
                    value={userPhone}
                    hasError={error.phone}
                    placeholder="Enter Phone number"
                    labelname="Phone"
                    maxLength={10}
                    onChange={(event: any) => {
                      setUserPhone(event.target.value);
                      setError((error) => ({
                        ...error,
                        phone: "",
                      }));
                    }}
                    onBlur={checkPhoneNumber}
                  />
                  {error.phone && <ErrorMessage>{error.phone}</ErrorMessage>}
                </div>
                <div className="col-md-6 pb-3">
                  <div style={{ marginTop: "9px" }}>
                    <InputDropdown
                      labelname="Role"
                      optionsArray={listOfRoles}
                      click={(value: any) => {
                        setRole(value);
                      }}
                      defaultValue={role}
                      no_shadow="true"
                    />
                  </div>
                </div>
                <div className="col-12 py-2">
                  <p className={`mb-0 ${Styles.label}`}>Status</p>
                </div>
                <div className="col-6">
                  <RadioButton
                    label="Active"
                    type="radio"
                    checked={status === statusTypes.active}
                    name="status"
                    onChange={() => {
                      setStatus(statusTypes.active);
                    }}
                  />
                </div>
                <div className="col-6">
                  <RadioButton
                    label="Disable"
                    type="radio"
                    checked={status === statusTypes.disabled}
                    name="status"
                    onChange={() => {
                      setStatus(statusTypes.disabled);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="py-4 my-2 d-flex align-items-center justify-content-center"
          style={{ borderTop: "1px solid #ECEEEF" }}
        >
          <div className="px-2">
            <Button
              size={MEDIUM}
              style={cancelButtonStyle}
              hoveredStyle={cancelButtonHoverStyle}
              disabled={buttonLoading}
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </div>
          <div className="px-2">
            <Button
              size={MEDIUM}
              style={primaryButtonStyle}
              hoveredStyle={primaryButtonHoverStyle}
              disabled={!is_allowed_to_write || isButtonDisabled()}
              isLoading={buttonLoading}
              onClick={() => updateUser()}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUserModal;

import React, { useContext, useEffect, useState } from "react";

import trackTaskStyle from "./trackTask.module.scss";
import ProgressCardCustomer from "./progressCardCustomer";

import { ToastContext } from "../../../../context/toastContext";
import { ERROR, SUCCESS } from "../../../../utils/toastType";
import Loading from "../../../../shared/loading/loading";
import { ACCENTCOLOR, SECONDARYCOLOR } from "../../../../shared/colors";
import ConfirmationModal from "../../../../shared/modal/confirmationModal";
import { callPostApi } from "../../../../api/axios";
import InputDropdown from "../../../../shared/input-dropdown/inputDropdown";
import { COMPRESSED } from "../../../../shared/buttonSize";
import {
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../shared/buttonStyles";
import Button from "../../../../shared/button/button";
import Pagination from "../../../../shared/pagination/pagination";

interface Props {
  loadingData: boolean;
  orgIdAndName: any;
  is_allowed_to_write: boolean;
  batchTypesList: any;
  taskList: any;
  setTaskList: Function;
  isfilterTask: boolean;
  getTaskData: Function;
  setIsfilterTask: Function;
  loading: boolean;
}

const TrackTask = ({
  loadingData,
  orgIdAndName,
  is_allowed_to_write,
  batchTypesList,
  taskList,
  setTaskList,
  isfilterTask,
  setIsfilterTask,
  getTaskData,
  loading,
}: Props) => {
  const dispatch = useContext(ToastContext);

  const [activePageNumber, setActivePageNumber] = React.useState(1);

  const [loadingForcallActionAPI, setLoadingForcallActionAPI] =
    useState<boolean>(false);
  const [confimationModal, setConfimationModal] = useState<any>({
    isModalOpen: false,
  });

  const [filter, setFilter] = useState<string>("Admin");

  const [selectedOption, setSelectedOption] = useState("Admin");

  const [postPerPage] = React.useState(20);
  const [showFilter, setShowFilter] = useState(false);
  const [filterOrg, setFilterOrgs] = useState<any>([]);
  const [state, setState] = useState<any>(null);

  const indexOfLastAccount = activePageNumber * postPerPage;
  const indexOfFirstAccount = indexOfLastAccount - postPerPage;
  const [filteredTaskList, setFilteredTaskList] = useState<Array<any>>([]);

  function getOrgNameById(org_id: string | null) {
    const org_name: any = orgIdAndName.find((item: any) => item._id === org_id);
    return org_name ? org_name.name : null;
  }

  function getOrgIdByName(org_name: string | null) {
    const org_id: any = orgIdAndName.find(
      (item: any) => item.name === org_name
    );
    return org_id ? org_id._id : null;
  }

  const dropdownData = [
    { label: "Completed", value: "FINISHED" },
    { label: "In Progress", value: "RUNNING" },
    { label: "Paused", value: "PAUSED" },
    { label: "Error", value: "ERROR" },
    { label: "Initialized", value: "INITIALIZED" },
  ];

  const handleOptionChange = (value: any) => {
    setSelectedOption(value);
    setFilter(value);
  };

  useEffect(() => {
    const uniqueOrgNames = new Set(
      taskList
        ?.filter((obj: any) => obj?.orgName ?? false)
        .map((obj: any) => obj?.orgName)
    );
    const orgNameById = new Set(
      taskList
        ?.filter((obj: any) => getOrgNameById(obj?.orgId) ?? false)
        .map((obj: any) => getOrgNameById(obj?.orgId))
    );

    const combinedOrgs = new Set([...uniqueOrgNames, ...orgNameById]);
    setFilterOrgs(Array.from(combinedOrgs));
  }, [taskList]);

  const successToast = (message: string) => {
    dispatch({
      type: "ADD_TOAST",
      payload: {
        id: Math.floor(Math.random() * 100),
        type: SUCCESS,
        message: message || "Success!",
      },
    });
  };

  function clearAll() {
    setState("");
    setFilteredTaskList([]);
    setIsfilterTask(false);
    setActivePageNumber(1);
  }

  const taskListData = taskList.filter((data: any) => {
    if (filter === "Customer") return data.uploadSource === "CUSTOMER";
    else if (filter === "Admin") return data.uploadSource === "ADMIN";
    else if (filter === "Async") return data.uploadSource === "ASYNC";
    else if (filter === "PDD") return data.uploadSource === "PDD";
    else if (filter === "Mini") return data.uploadSource === "MINI";
  });

  function applyFilter() {
    if (state?.fromDate || state?.toDate) {
      if (state.fromDate && !state.toDate) {
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: "Please Select Both Dates",
          },
        });
        return;
      }
      if (!state.fromDate && state.toDate) {
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: "Please Select Both Dates",
          },
        });
        return;
      }
      if (new Date(state.fromDate) > new Date(state.toDate)) {
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: "Please Select A valid Date Range",
          },
        });
        return;
      }
    }

    const filterData = taskListData.filter((item: any) => {
      const date = item.created_at ? item.created_at.split("T")[0] : null;

      return (
        (!state?.name ||
          item.orgName === state.name ||
          item.orgId === getOrgIdByName(state.name)) &&
        (!state?.batchType || item.batchType === state?.batchType) &&
        (!state?.value || item.batchState === state?.value) &&
        ((!state.fromDate && !state.toDate) ||
          (new Date(date) >= new Date(state.fromDate) &&
            new Date(date) <= new Date(state.toDate)))
      );
    });

    if (filterData.length === 0 && state) {
      setFilteredTaskList([]);
      setIsfilterTask(true);
    } else if (filterData.length > 0 && state) {
      setFilteredTaskList(filterData);
      setActivePageNumber(1);
    }
  }

  const errorToast = (message: string) => {
    dispatch({
      type: "ADD_TOAST",
      payload: {
        id: Math.floor(Math.random() * 100),
        type: ERROR,
        message: message || "Something went wrong!",
      },
    });
  };

  // to update the current state of task.
  const callActionAPI = async (batchId: string, type: string) => {
    let endpoint = "";
    switch (type) {
      case "START_TASK":
        endpoint = "startBatch/" + batchId;
        break;
      case "PAUSE_TASK":
        endpoint = "pauseBatch/" + batchId;
        break;

      case "STOP_TASK":
        endpoint = "stopBatch/" + batchId;
        break;
      case "GENERATE_LINK":
        endpoint = "generateDownloadLink/" + batchId;
        break;

      case "DELETE_TASK":
        endpoint = "task/deleteTask/" + batchId;
        break;

      default:
        break;
    }

    if (endpoint) {
      try {
        setLoadingForcallActionAPI(true);
        const response: any = await callPostApi(
          "productBatchService/executeAction",
          {
            action: endpoint,
          }
        );

        getTaskData();
        setConfimationModal({
          isModalOpen: false,
        });

        if (response?.success === true) {
          successToast(response?.message || "");
        } else {
          errorToast(response?.message || "something went wrong");
        }
        setLoadingForcallActionAPI(false);
      } catch (error) {
        setLoadingForcallActionAPI(false);
        errorToast("Something went wrong!");
        setConfimationModal({
          isModalOpen: false,
        });
      }
    }
  };

  const userConfirmation = (batchId: string, type: string) => {
    if (type === "STOP_TASK") {
      setConfimationModal({
        subTitle: "Are you sure you want to stop the task?",
        btnText: "Yes",
        isModalOpen: true,
        batchId,
        type,
      });
    } else if (type === "PAUSE_TASK") {
      setConfimationModal({
        subTitle: "Are you sure you want to Pause the task?",
        btnText: "Yes",
        isModalOpen: true,
        batchId,
        type,
      });
    } else if (type === "START_TASK") {
      setConfimationModal({
        subTitle: "Are you sure you want to start the task?",
        btnText: "Yes",
        isModalOpen: true,
        batchId,
        type,
      });
    } else {
      setConfimationModal({
        subTitle: "Are you sure you want to delete the task?",
        btnText: "Yes",
        isModalOpen: true,
        batchId,
        type,
      });
    }
  };

  useEffect(() => {
    setActivePageNumber(1);
  }, [filter]);

  return (
    <div>
      {loading ? (
        <div
          style={{ height: "50vh" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Loading loadingColor={ACCENTCOLOR} />
        </div>
      ) : (
        <>
          <div
            className="d-flex justify-content-between flex-wrap"
            style={{ marginBottom: "10px" }}
          >
            <div className={trackTaskStyle.headingStyle}>
              Select Source Type
            </div>
            <div className={trackTaskStyle.refreshbtn}>
              <Button
                size={COMPRESSED}
                style={primaryButtonStyle}
                hoveredStyle={primaryButtonHoverStyle}
                onClick={() => {
                  getTaskData();
                  clearAll();
                }}
              >
                Refresh
              </Button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              gap: "25px",
              alignItems: "center",
              justifyContent: "start",
              flexWrap: "wrap",
            }}
            className={trackTaskStyle.borderfilter}
          >
            <div className={trackTaskStyle.radioText1}>
              <input
                type="radio"
                name="flexRadio"
                value="Admin"
                style={{ cursor: "pointer" }}
                checked={selectedOption === "Admin"}
                onChange={() => handleOptionChange("Admin")}
              />
              <label className={trackTaskStyle.label}>Admin</label>
            </div>
            <div className={trackTaskStyle.radioText}>
              <input
                type="radio"
                name="flexRadio"
                style={{ cursor: "pointer" }}
                value="Customer"
                checked={selectedOption === "Customer"}
                onChange={() => handleOptionChange("Customer")}
              />
              <label className={trackTaskStyle.label}>Customer</label>
            </div>
            <div className={trackTaskStyle.radioText}>
              <input
                type="radio"
                name="flexRadio"
                style={{ cursor: "pointer" }}
                value="Async"
                checked={selectedOption === "Async"}
                onChange={() => handleOptionChange("Async")}
              />
              <label className={trackTaskStyle.label}>Async</label>
            </div>
            <div className={trackTaskStyle.radioText}>
              <input
                type="radio"
                name="flexRadio"
                style={{ cursor: "pointer" }}
                value="Async"
                checked={selectedOption === "PDD"}
                onChange={() => handleOptionChange("PDD")}
              />
              <label className={trackTaskStyle.label}>PDD</label>
            </div>

            <div className={trackTaskStyle.radioText}>
              <input
                type="radio"
                name="flexRadio"
                style={{ cursor: "pointer" }}
                value="Async"
                checked={selectedOption === "Mini"}
                onChange={() => handleOptionChange("Mini")}
              />
              <label className={trackTaskStyle.label}>Mini</label>
            </div>
          </div>

          {taskListData.length > 0 && (
            <div className={trackTaskStyle.trackTaskFilterDiv}>
              <div
                className={trackTaskStyle.headingStyle}
                style={{ marginTop: "15px" }}
              >
                Filter By
              </div>
              <div style={{ marginTop: "15px", cursor: "pointer" }}>
                <div
                  className={showFilter ? trackTaskStyle.roatate90 : ""}
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <p>{">"}</p>
                </div>
              </div>
            </div>
          )}

          {!showFilter && taskListData.length > 0 && <hr />}
          {showFilter && taskListData.length > 0 && (
            <>
              <div className={trackTaskStyle.filterBorder}>
                <div className="d-flex flex-wrap flex-container">
                  <div className={trackTaskStyle.dropdown2}>
                    <InputDropdown
                      labelname="Org Name"
                      showDropDownName="Org Name"
                      searchable={true}
                      optionsArray={filterOrg}
                      click={(key: string) => {
                        const option = filterOrg.filter(
                          (item: any) => item === key
                        );
                        setState((data: any) => ({
                          ...data,
                          name: option[0] || "",
                        }));
                      }}
                      defaultValue={state?.name}
                      PlaceHolderSearch={"Search Organisation Id"}
                      // onBlur={checkBatchType}
                      whiteBackgroundcolor={true}
                    />
                  </div>
                  <div className={trackTaskStyle.dropdown2}>
                    <InputDropdown
                      labelname="Product Name"
                      showDropDownName="Product Name"
                      searchable={true}
                      optionsArray={batchTypesList.map(
                        (item: any) => item?.name
                      )}
                      click={(key: string) => {
                        const option = batchTypesList.filter(
                          (item: any) => item?.name === key
                        );
                        setState((data: any) => ({
                          ...data,
                          batchType: option[0]?.value || "",
                        }));
                      }}
                      defaultValue={`${
                        batchTypesList.filter(
                          (item: any) => item?.value === state?.batchType
                        )[0]?.name || ""
                      }`}
                      // onBlur={checkBatchType}
                      whiteBackgroundcolor={true}
                    />
                  </div>
                  <div className={trackTaskStyle.customDatePicker}>
                    <div
                      className={trackTaskStyle.formLabel}
                      style={{ marginBottom: "8px" }}
                    >
                      Created at
                    </div>
                    <div className={trackTaskStyle.datePicker}>
                      <div
                        // htmlFor="datePicker"
                        className={trackTaskStyle.formLabel}
                        style={{ marginTop: "8px" }}
                      >
                        From:
                      </div>
                      <input
                        type="date"
                        id="datePicker"
                        name="datePicker"
                        style={{
                          width: "auto",
                          borderRadius: "8px",
                          padding: "8px",
                          backgroundColor: "#fff",
                          border: "1px solid #DDDCDD",
                          marginLeft: "15px",
                          marginRight: "8px",
                          fontWeight: 100,
                          fontSize: "12px",
                          color: SECONDARYCOLOR,
                        }}
                        className={trackTaskStyle.dateInput}
                        max={new Date().toISOString().split("T")[0]}
                        value={state?.fromDate ? state?.fromDate : ""}
                        onChange={(e: any) => {
                          setState((data: any) => ({
                            ...data,
                            fromDate: e.target.value,
                          }));
                        }}
                      />
                      <div
                        className={trackTaskStyle.formLabel}
                        style={{ marginTop: "8px" }}
                      >
                        To:
                      </div>
                      <input
                        type="date"
                        id="datePicker"
                        name="datePicker"
                        style={{
                          width: "auto",
                          borderRadius: "8px",
                          padding: "8px",
                          fontSize: "12px",
                          backgroundColor: "#fff",
                          border: "1px solid #DDDCDD",
                          marginLeft: "15px",
                          fontWeight: 100,
                          color: SECONDARYCOLOR,
                        }}
                        value={state?.toDate ? state?.toDate : ""}
                        max={new Date().toISOString().split("T")[0]}
                        onChange={(e: any) => {
                          setState((data: any) => ({
                            ...data,
                            toDate: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  <div className={trackTaskStyle.dropdown2}>
                    <InputDropdown
                      labelname="Status"
                      showDropDownName="Select Status"
                      no_shadow="true"
                      hasError={""}
                      isValid={true}
                      optionsArray={dropdownData.map(
                        (category: any) => category.label
                      )}
                      defaultValue={state?.label}
                      click={(value: any) => {
                        const selectedValue = dropdownData.find(
                          (category: any) => category.label === value
                        );
                        setState((data: any) => ({
                          ...data,
                          value: selectedValue?.value || "",
                          label: selectedValue?.label || "",
                        }));
                      }}
                      whiteBackgroundcolor={true}
                    />
                  </div>
                </div>
                <div
                  className="d-flex flex-wrap "
                  style={{ display: "flex", gap: "20px" }}
                >
                  <div className={trackTaskStyle.filterButtonclearButton}>
                    <Button
                      style={primaryButtonStyle}
                      hoveredStyle={primaryButtonHoverStyle}
                      size={COMPRESSED}
                      onClick={applyFilter}
                    >
                      Apply Filter
                    </Button>
                  </div>
                  <div className={trackTaskStyle.filterButtonclearButton}>
                    <p
                      style={{ color: "#3272EC", cursor: "pointer" }}
                      onClick={() => clearAll()}
                    >
                      Clear all
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}

          {taskListData.length > 0 &&
          filteredTaskList.length === 0 &&
          !isfilterTask ? (
            <>
              {taskListData
                .slice(indexOfFirstAccount, indexOfLastAccount)
                .map((data: any, index: any) => (
                  <div
                    className="mb-4 pt-1 pb-1 ml-3"
                    key={"Track_Task_" + index}
                  >
                    <ProgressCardCustomer
                      taskData={data}
                      callActionAPI={callActionAPI}
                      userConfirmation={userConfirmation}
                      activePageNumber={activePageNumber}
                      successToast={successToast}
                      errorToast={errorToast}
                      orgIdAndName={orgIdAndName}
                      filter={filter}
                      is_allowed_to_write={is_allowed_to_write}
                    />
                  </div>
                ))}
            </>
          ) : filteredTaskList.length > 0 ? (
            <>
              {filteredTaskList
                .slice(indexOfFirstAccount, indexOfLastAccount)
                .map((data, index) => (
                  <div
                    className="mb-4 pt-1 pb-1 ml-3"
                    key={"Filtered_Task_" + index}
                  >
                    <ProgressCardCustomer
                      orgIdAndName={orgIdAndName}
                      taskData={data}
                      activePageNumber={activePageNumber}
                      callActionAPI={callActionAPI}
                      userConfirmation={userConfirmation}
                      successToast={successToast}
                      errorToast={errorToast}
                      filter={filter}
                      is_allowed_to_write={is_allowed_to_write}
                    />
                  </div>
                ))}
            </>
          ) : (
            <div
              style={{ height: "50vh" }}
              className="d-flex align-items-center justify-content-center"
            >
              No tasks found
            </div>
          )}

          {confimationModal.isModalOpen && (
            <ConfirmationModal
              title={confimationModal?.title}
              subTitle={confimationModal?.subTitle}
              btnText={confimationModal?.btnText}
              isLoading={loadingForcallActionAPI}
              onCancel={() =>
                setConfimationModal({
                  isModalOpen: false,
                })
              }
              forBatch={true}
              onSuccess={() => {
                callActionAPI(
                  confimationModal?.batchId,
                  confimationModal?.type
                );
              }}
            />
          )}
        </>
      )}

      <div className=" d-flex align-items-center justify-content-center">
        {taskList.length > 0 &&
        filteredTaskList.length === 0 &&
        !isfilterTask &&
        !loading ? (
          <div>
            <Pagination
              activePageNumber={activePageNumber}
              total={taskListData.length}
              postPerPage={postPerPage}
              navigateToPage={(value: number) => {
                setActivePageNumber(value);
              }}
            />
          </div>
        ) : (
          <div>
            <Pagination
              activePageNumber={activePageNumber}
              nextButtonShow={isfilterTask}
              total={filteredTaskList.length}
              postPerPage={postPerPage}
              navigateToPage={(value: number) => {
                setActivePageNumber(value);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TrackTask;

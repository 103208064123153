import React, { useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Styles from "./account.module.scss";
import sharedStyle from "../../../../shared/sharedStyle.module.scss";
import RupeeSVG from "../../../../shared/svg/rupeeSvg";
import { ACCENTCOLOR, PRIMARYCOLOR } from "../../../../shared/colors";
import {
  accountNavTabs,
  ACCOUNT_DETAILS,
  ACTIVITIES,
  BILLING,
  KYC_DETAILS,
  PRODUCTS,
} from "../../../../constants/accountsNavTabs";
import AccountDetails from "./account-details/accountDetails";
import KycDetails from "./kyc-details/kycDetails";
import Billing from "./billing/billing";
import Products from "./products/products";
import Activities from "./activities/activities";
import { callGetApi } from "../../../../api/axios";
import Loading from "../../../../shared/loading/loading";
import { ERROR } from "../../../../utils/toastType";
import { monthName } from "../../../../utils/getMonth";
import { ToastContext } from "../../../../context/toastContext";
import {
  numberWithCommas,
  getAmountWithGST,
  roundOfDecimal,
} from "../../../../utils/updateAmount";
import { PermissionContext } from "../../../../context/permissionContext";
import { accounts } from "../../../../constants/navItems";
import { ApmContext } from "../../../../utils/apmProvider";

function Account() {
  const planTypes = {
    PREPAID: "PREPAID",
    POSTPAID: "POSTPAID",
  };
  const { assets, permissions: permissionsType } =
    useContext(PermissionContext);
  const accountsPermissions = assets[accounts];
  const params: any = useParams();
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);
  const [activeTab, setActiveTab] = React.useState("");
  const [navItems, setNavItems] = React.useState<string[]>([]);
  const [accountDetails, setAccountDetails] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);
  const [orgCategories, setOrgCategories] = React.useState([]);
  const [countryCodes, setCountryCodes] = React.useState([]);
  const accountPageRef: any = useRef();
  const dispatch = useContext(ToastContext);
  const [fetchUpdatedAccount, setFetchUpdatedAccountInfo] =
    React.useState(false);
  const [onboardingStatus, setOnboardingStatus] = React.useState<string>("");

  useEffect(() => {
    const set: Set<string> = new Set();
    for (const grants of Object.values(accountsPermissions) as any) {
      for (const is_allowed of Object.values(grants.permissions)) {
        if (!is_allowed) continue;
        for (const tabName of accountNavTabs) {
          if (
            grants.grant.endsWith(tabName.replaceAll(" ", "_").toLowerCase())
          ) {
            set.add(tabName);
          }
        }
      }
    }
    setNavItems(Array.from(set));
    setActiveTab(Array.from(set)[0]);
  }, []);

  useEffect(() => {
    async function getAccountDetails() {
      setLoading(true);
      try {
        const response: any = await callGetApi(
          `/organisations/${params.id}?data=account_info`
        );

        setLoading(false);

        setAccountDetails({
          ...response.data,
          bm: response.data.business_manager,
        });

        setOnboardingStatus(response.data.onboarding_status ?? "ORG CREATED");
      } catch (err: any) {
        setLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message:
              err.response.data.err +
              " TraceId is \n" +
              apmTraceId(apmInstance),
            copy: apmTraceId(apmInstance),
          },
        });
      }
    }

    getAccountDetails();

    accountPageRef.current.scrollIntoView({
      behavior: "smooth",
      inline: "start",
    });
  }, [params.id, dispatch, fetchUpdatedAccount]);

  useEffect(() => {
    async function getOrgCategories() {
      try {
        const response: any = await callGetApi("/organisations/categories");
        // const categories = response.data.map((category: any) => category.name);
        setOrgCategories(response?.data || []);
      } catch (err: any) {
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message:
              err.response.data.err +
              " TraceId is \n" +
              apmTraceId(apmInstance),
            copy: apmTraceId(apmInstance),
          },
        });
      }
    }

    getOrgCategories();
  }, [dispatch]);

  useEffect(() => {
    async function getAllCountryCodes() {
      try {
        const response: any = await callGetApi("/country");
        let listOfCodeAndNames = response.data.map(
          (country: any) => `${country.code} - ${country.name}`
        );
        // to make IN - India first element in an array.
        listOfCodeAndNames = listOfCodeAndNames.filter(
          (code: String) => code !== "IN - India"
        );
        listOfCodeAndNames.unshift("IN - India");
        setCountryCodes(listOfCodeAndNames);
      } catch (err: any) {
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message:
              err.response.data.err +
              " TraceId is \n" +
              apmTraceId(apmInstance),
            copy: apmTraceId(apmInstance),
          },
        });
      }
    }

    getAllCountryCodes();
  }, [dispatch]);
  function duration() {
    const date = new Date(Number(accountDetails?.createdAt));
    return `${monthName(
      date.getMonth()
    )} ${date.getDate()}, ${date.getFullYear()}`;
  }
  return (
    <div className="pl-3" ref={accountPageRef}>
      {loading ? (
        <div
          style={{ height: "110px" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Loading loadingColor={ACCENTCOLOR} />
        </div>
      ) : (
        <div>
          <div className="py-2 d-flex align-items-center">
            <p className={`mb-0 ${Styles.accountName}`}>
              {accountDetails?.name}
            </p>
          </div>
          <div
            className="py-2 d-flex align-items-center"
            style={{ overflow: "auto" }}
          >
            <div className="pr-4 py-2">
              <div
                className={`p-4 ${sharedStyle.card}`}
                style={{ width: "250px" }}
              >
                <p className={`mb-0 ${Styles.key}`}>Active Since</p>
                <p className={`mb-0 ${Styles.value}`}>{duration()}</p>
              </div>
            </div>
            <div className="pr-4 py-2">
              <div
                className={`p-4 ${sharedStyle.card}`}
                style={{ width: "250px" }}
              >
                <p className={`mb-0 ${Styles.key}`}>
                  {accountDetails?.active_plan?.plan_type === planTypes.POSTPAID
                    ? "Current Consumption"
                    : "Current Balance"}
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="pr-2">
                    <RupeeSVG
                      margin="0 0 3px 0"
                      width="8"
                      height="12"
                      color={PRIMARYCOLOR}
                    />
                  </div>
                  <p className={`mb-0 ${Styles.value}`}>
                    {accountDetails?.wallet_balance ||
                    accountDetails?.wallet_balance === 0
                      ? numberWithCommas(
                          roundOfDecimal(
                            getAmountWithGST(accountDetails.wallet_balance)
                          )
                        )
                      : "NA"}
                  </p>
                </div>
              </div>
            </div>
            <div className="pr-4 py-2">
              <div
                className={`p-4 ${sharedStyle.card}`}
                style={{ width: "250px" }}
              >
                <p className={`mb-0 ${Styles.key}`}>
                  {accountDetails?.active_plan?.plan_type === planTypes.POSTPAID
                    ? "Daily Consumption"
                    : "Balance Alert"}
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="pr-2">
                    <RupeeSVG
                      margin="0 0 3px 0"
                      width="8"
                      height="12"
                      color={PRIMARYCOLOR}
                    />
                  </div>
                  <p className={`mb-0 ${Styles.value}`}>
                    {accountDetails?.alert_balance ||
                    accountDetails.alert_balance === 0
                      ? numberWithCommas(
                          roundOfDecimal(accountDetails.alert_balance)
                        )
                      : "0"}
                  </p>
                </div>
              </div>
            </div>
            <div className="pr-4 py-2">
              <div
                className={`p-4 ${sharedStyle.card}`}
                style={{ width: "250px" }}
              >
                <p className={`mb-0 ${Styles.key}`}>Plan Active</p>
                <div className="d-flex align-items-center justify-content-center">
                  <p className={`mb-0 ${Styles.value} px-2`}>
                    {accountDetails?.active_plan
                      ? accountDetails.active_plan.plan_name
                      : "NA"}
                  </p>
                  {accountDetails?.active_plan?.plan_type ===
                    planTypes.POSTPAID && (
                    <div className={`${Styles.post_paid_plan_bubble} px-2`}>
                      <p className={Styles.post_paid_plan_text}>Postpaid</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="pr-4 py-2">
              <div
                className={`p-4 ${sharedStyle.card}`}
                style={{ width: "250px" }}
              >
                <p className={`mb-0 ${Styles.key}`}>Expiry Date</p>
                <p className={`mb-0 ${Styles.value}`}>
                  {accountDetails?.active_plan
                    ? accountDetails?.active_plan.expiry_date
                      ? `${monthName(
                          new Date(
                            Number(accountDetails?.active_plan.expiry_date)
                          ).getMonth()
                        )} ${new Date(
                          Number(accountDetails?.active_plan.expiry_date)
                        ).getDate()}, ${new Date(
                          Number(accountDetails?.active_plan.expiry_date)
                        ).getFullYear()}`
                      : "NA"
                    : "NA"}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className="pt-3 d-flex align-items-center"
        style={{ borderBottom: "1px solid #ddd" }}
      >
        {navItems.map((tab, index) => {
          return (
            <div
              key={index}
              className={Styles.navItemWrapper}
              onClick={() => setActiveTab(tab)}
            >
              <p
                className={`mb-0 px-4 ${
                  activeTab === tab ? Styles.activeNavItem : Styles.navItem
                }`}
                style={
                  index !== navItems.length - 1
                    ? { borderRight: `1px solid #ddd` }
                    : { borderRight: 0 }
                }
              >
                {tab}
              </p>
              <div
                className={
                  activeTab === tab
                    ? Styles.activeBorder
                    : Styles.nonActiveBorder
                }
              ></div>
            </div>
          );
        })}
      </div>
      <div className="py-3">
        {activeTab === ACCOUNT_DETAILS && (
          <AccountDetails
            accountDetails={accountDetails}
            org_status={accountDetails?.status}
            isLoading={loading}
            countryCodes={countryCodes}
            categories={orgCategories}
            updateAccountDetails={(updatedAccountDetails: any) => {
              setAccountDetails((accountDetails: any) => ({
                ...accountDetails,
                ...updatedAccountDetails,
              }));
            }}
            is_allowed_to_write={
              accountsPermissions[ACCOUNT_DETAILS].permissions[
                `${permissionsType.write}_${accountsPermissions[ACCOUNT_DETAILS].grant}`
              ]
            }
          />
        )}
        {activeTab === KYC_DETAILS && (
          <KycDetails
            org_status={accountDetails?.status}
            is_allowed_to_write={
              accountsPermissions[KYC_DETAILS].permissions[
                `${permissionsType.write}_${accountsPermissions[KYC_DETAILS].grant}`
              ]
            }
          />
        )}
        {activeTab === BILLING && (
          <Billing
            country_code={accountDetails.country_code}
            org_name={accountDetails?.name}
            org_status={accountDetails?.status}
            alert_balance={accountDetails.alert_balance}
            getUpdateAccountInfo={() =>
              fetchUpdatedAccount
                ? setFetchUpdatedAccountInfo(false)
                : setFetchUpdatedAccountInfo(true)
            }
            onUpdateAmount={(value: number) =>
              setAccountDetails((accountDetails: any) => ({
                ...accountDetails,
                wallet_balance: Math.round(
                  Number(accountDetails.wallet_balance) + Number(value)
                ),
              }))
            }
            is_allowed_to_write={
              accountsPermissions[BILLING].permissions[
                `${permissionsType.write}_${accountsPermissions[BILLING].grant}`
              ]
            }
          />
        )}
        {activeTab === PRODUCTS && (
          <Products
            org_status={accountDetails?.status}
            is_allowed_to_write={
              accountsPermissions[PRODUCTS].permissions[
                `${permissionsType.write}_${accountsPermissions[PRODUCTS].grant}`
              ]
            }
            is_allowed_to_delete={
              accountsPermissions[PRODUCTS].permissions[
                `${permissionsType.delete}_${accountsPermissions[PRODUCTS].grant}`
              ]
            }
          />
        )}
        {activeTab === ACTIVITIES && (
          <Activities
            org_status={accountDetails?.status}
            onUpdateStatus={(value: string) => {
              setAccountDetails((accountDetails: any) => ({
                ...accountDetails,
                status: value,
              }));
            }}
            onboarding_status={onboardingStatus}
            is_allowed_to_delete={
              accountsPermissions[ACTIVITIES].permissions[
                `${permissionsType.delete}_${accountsPermissions[ACTIVITIES].grant}`
              ]
            }
          />
        )}
      </div>
    </div>
  );
}

export default Account;

import React, { useContext, useEffect } from "react";
import { callGetApi, callPutApi } from "../../../../../api/axios";
import { roleType } from "../../../../../constants/userRoles";
import { ToastContext } from "../../../../../context/toastContext";
import Button from "../../../../../shared/button/button";
import { MEDIUM } from "../../../../../shared/buttonSize";
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  successButtonHoverStyle,
  successButtonStyle,
} from "../../../../../shared/buttonStyles";
import { ACCENTCOLOR } from "../../../../../shared/colors";
import InputDropdown from "../../../../../shared/input-dropdown/inputDropdown";
import KeyValuePair from "../../../../../shared/key-value-pair/keyValuePair";
import Loading from "../../../../../shared/loading/loading";
import popUpStyles from "../../../../../shared/popup.module.scss";
import sharedStyle from "../../../../../shared/sharedStyle.module.scss";
import { ERROR } from "../../../../../utils/toastType";
import { ApmContext } from "../../../../../utils/apmProvider";

function ModifyAccountManager({
  accountInfo = {},
  action,
  onCancel,
  onUpdate,
  org_id,
}: any) {
  const [tempKamDetails, setTempKamDetails] = React.useState({
    kam_name: accountInfo?.kam?.kam_name,
    kam_email: accountInfo?.kam?.kam_email,
    kam_phone: accountInfo?.kam?.kam_phone,
    id: accountInfo?.kam?.id,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [listOfKam, setListOfKam] = React.useState<any>([]);
  const [isKamModified, setIsKamModified] = React.useState(false);
  const dispatch = useContext(ToastContext);
  useEffect(() => {
    async function getAllAccountManagers() {
      setIsLoading(true);
      try {
        const response: any = await callGetApi("/kam");
        const data = response.data
          .filter(
            (kam: any) =>
              kam.status === "ACTIVE" &&
              [roleType.kam, roleType.admin].includes(kam.role)
          )
          .map((kam: any, index: number) => {
            const {
              name = `no_name_${index}`,
              email,
              _id,
              phone = `no_phone_${index}`,
            } = kam;
            return {
              kam_name: name,
              kam_email: email,
              kam_phone: phone,
              id: _id,
            };
          });
        setListOfKam(data);
        setIsLoading(false);
      } catch (err: any) {
        setIsLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message:
              err.response.data.err +
              " TraceId is \n" +
              apmTraceId(apmInstance),
            copy: apmTraceId(apmInstance),
          },
        });
      }
    }
    getAllAccountManagers();
  }, [dispatch]);
  async function updateKam() {
    setButtonLoading(true);
    try {
      const response: any = await callPutApi(
        `/organisations/${org_id}?action=update_kam`,
        {
          kam_id: tempKamDetails.id,
        }
      );
      const { kam } = response.data;
      setButtonLoading(false);
      onUpdate(kam);
    } catch (err: any) {
      setButtonLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message:
            err.response.data.err + " TraceId is \n" + apmTraceId(apmInstance),
          copy: apmTraceId(apmInstance),
        },
      });
    }
  }
  const loading = (
    <div
      style={{ height: "230px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Loading loadingColor={ACCENTCOLOR} />
    </div>
  );
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${popUpStyles.overlay}`}
    >
      <div className={`${popUpStyles.popUpContent}`} style={{ width: "500px" }}>
        {isLoading ? (
          loading
        ) : (
          <div>
            <div className={`p-4 ${sharedStyle.cardHeaderBack}`}>
              <p className={`mb-0 ${sharedStyle.cardHeader}`}>
                {action} Account Manager
              </p>
            </div>
            <div className="px-4 pt-4 mx-2">
              <div className="py-2">
                <InputDropdown
                  labelname="Account Manager"
                  optionsArray={listOfKam.map((kam: any) => kam.kam_name)}
                  click={(value: string, index: number) => {
                    setIsKamModified(true);
                    /* const selectedKam1 = listOfKam.find(
                      (kam: any) => value === kam.kam_name
                    ); */
                    const selectedKam =
                      listOfKam[index].kam_name === value
                        ? listOfKam[index]
                        : listOfKam.find((kam: any) => value === kam.kam_name);
                    setTempKamDetails((kamDetails: any) => ({
                      ...kamDetails,
                      ...selectedKam,
                    }));
                  }}
                  defaultValue={
                    tempKamDetails.kam_name ?? "name of the manager"
                  }
                  no_shadow="true"
                />
              </div>
              <div className="py-3 container-fluid">
                <div className="row no-gutters">
                  <div className="col-sm-6">
                    <KeyValuePair
                      className="py-2"
                      label="Contact"
                      value={tempKamDetails.kam_phone ?? "NA"}
                    />
                  </div>
                  <div className="col-sm-6">
                    <KeyValuePair
                      className="py-2"
                      label="Email"
                      value={tempKamDetails.kam_email ?? "NA"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="py-4 my-2 d-flex align-items-center justify-content-center"
              style={{ borderTop: "1px solid #ECEEEF" }}
            >
              <div className="px-2">
                <Button
                  disabled={buttonLoading}
                  size={MEDIUM}
                  style={cancelButtonStyle}
                  hoveredStyle={cancelButtonHoverStyle}
                  onClick={() => onCancel()}
                >
                  Cancel
                </Button>
              </div>
              <div className="px-2">
                <Button
                  size={MEDIUM}
                  disabled={buttonLoading || !isKamModified}
                  isLoading={buttonLoading}
                  style={successButtonStyle}
                  hoveredStyle={successButtonHoverStyle}
                  onClick={() => updateKam()}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ModifyAccountManager;

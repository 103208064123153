import React, { useContext } from "react";
import Styles from "../kycDetails.module.scss";
import sharedStyle from "../../../../../../shared/sharedStyle.module.scss";
import {
  documentType,
  directorDocumentType,
} from "../../../../../../constants/registrationDocumentTypes";
import Button from "../../../../../../shared/button/button";
import { COMPRESSED } from "../../../../../../shared/buttonSize";
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  successButtonHoverStyle,
  successButtonStyle,
} from "../../../../../../shared/buttonStyles";
import InputDropdown from "../../../../../../shared/input-dropdown/inputDropdown";
import UpdateSvg from "../../../../../../shared/svg/updateSvg";
import Input from "../../../../../../shared/input/input";
import ErrorMessage from "../../../../../../shared/error-message/errorMessage";
import KeyValuePair from "../../../../../../shared/key-value-pair/keyValuePair";
import { callPutApi } from "../../../../../../api/axios";
import Loading from "../../../../../../shared/loading/loading";
import { ACCENTCOLOR } from "../../../../../../shared/colors";
import { ERROR, SUCCESS } from "../../../../../../utils/toastType";
import { checkIsEmpty, isValidGSTIN } from "../../../../../../utils/validation";
import { ToastContext } from "../../../../../../context/toastContext";
import ApproveKycDetailsModal from "../approve-kyc-details-modal/approveKycDetailsModal";
import { orgStatus } from "../../../../../../constants/orgStatus";
import { ApmContext } from "../../../../../../utils/apmProvider";

function InternationalCustomers({
  directorDoc = {},
  auth_signatory = {},
  registrationDoc = {},
  gstin,
  isLoading,
  org_id,
  org_status,
  onUpdateKycDetails,
  onboarding_status,
  updatedInternationalVirtualAccount,
  is_allowed_to_write,
}: any) {
  const [kycLoading, setKycLoading] = React.useState(isLoading);
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  const [onboardingStatus, setOnboardingStatus] =
    React.useState(onboarding_status);
  const [toggleUpdateKYCDetails, setToggleUpdateKYCDetails] =
    React.useState(false);
  const [tempRegistrationDoc, setTempRegistrationDoc] = React.useState({
    doc_name: registrationDoc.doc_name ?? "",
    doc_number: registrationDoc.doc_number ?? "",
  });
  const [tempDirectorDoc, setTempDirectorDoc] = React.useState({
    doc_name: directorDoc.doc_name ?? "",
    doc_number: directorDoc.doc_number ?? "",
  });
  const [tempGSTINNumber, setTempGSTINNumber] = React.useState({
    gstin: gstin ?? "",
  });
  const [tempAuthorisedSignatory, setTempAuthorisedSignatory] = React.useState({
    signatory_name: auth_signatory.signatory_name ?? "",
    signatory_designation: auth_signatory.signatory_designation ?? "",
  });
  const [error, setError] = React.useState({
    selected_registration_doc: "",
    registration_doc: "",
    selected_director_doc: "",
    director_doc: "",
    gstin_number: "",
    auth_signatory_name: "",
    auth_signatory_designation: "",
  });
  const [validData, setValidData] = React.useState({
    selected_registration_doc: false,
    registration_doc: false,
    selected_director_doc: false,
    director_doc: false,
    gstin_number: false,
    auth_signatory_name: false,
    auth_signatory_designation: false,
  });
  const [toggleApproveAccountButton, setToggleApproveAccountButton] =
    React.useState(false);
  const dispatch = useContext(ToastContext);
  const alphaNumericRegexp = /^[a-zA-Z0-9]+$/;
  const alphaNumericNameRegexp = /^[a-zA-Z0-9 ]+$/;
  function checkGstinNumber() {
    if (checkIsEmpty(tempGSTINNumber.gstin)) {
      setError((error) => ({
        ...error,
        gstin_number: "GSTIN number cannot be empty",
      }));
      setValidData((validData) => ({
        ...validData,
        gstin_number: false,
      }));
      return false;
    }
    if (
      !isValidGSTIN(tempGSTINNumber.gstin) ||
      tempGSTINNumber.gstin.length > 15
    ) {
      setError((error) => ({
        ...error,
        gstin_number: "Enter a valid GSTIN number",
      }));
      setValidData((validData) => ({
        ...validData,
        gstin_number: false,
      }));
      return false;
    }
    return true;
  }
  function checkAuthSignatoryName() {
    if (checkIsEmpty(tempAuthorisedSignatory.signatory_name)) {
      setError((error) => ({
        ...error,
        auth_signatory_name: "Please enter a Authorised Signatory name",
      }));
      setValidData((validData) => ({
        ...validData,
        auth_signatory_name: false,
      }));
      return false;
    }
    if (!alphaNumericNameRegexp.test(tempAuthorisedSignatory.signatory_name)) {
      setError((error) => ({
        ...error,
        auth_signatory_name: "Special character not allowed",
      }));
      setValidData((validData) => ({
        ...validData,
        auth_signatory_name: false,
      }));
      return false;
    }
    return true;
  }
  function checkAuthSignatoryDesignation() {
    if (checkIsEmpty(tempAuthorisedSignatory.signatory_designation)) {
      setError((error) => ({
        ...error,
        auth_signatory_designation:
          "Please enter Authorised Signatory Designation",
      }));
      setValidData((validData) => ({
        ...validData,
        auth_signatory_designation: false,
      }));
      return false;
    }
    if (
      !alphaNumericNameRegexp.test(
        tempAuthorisedSignatory.signatory_designation
      )
    ) {
      setError((error) => ({
        ...error,
        auth_signatory_designation: "Special character not allowed",
      }));
      setValidData((validData) => ({
        ...validData,
        auth_signatory_designation: false,
      }));
      return false;
    }
    return true;
  }
  function checkRegistrationDocument() {
    if (!tempRegistrationDoc.doc_name) {
      setError((error) => ({
        ...error,
        selected_registration_doc: "please select a document type",
      }));
      setValidData((validData) => ({
        ...validData,
        selected_registration_doc: false,
      }));
      return false;
    }
    if (checkIsEmpty(tempRegistrationDoc.doc_number)) {
      setError((error) => ({
        ...error,
        registration_doc: `${tempRegistrationDoc.doc_name} number cannot be empty`,
      }));
      setValidData((validData) => ({
        ...validData,
        registration_doc: false,
      }));
      return false;
    }
    if (tempRegistrationDoc.doc_number.indexOf(" ") >= 0) {
      setError((error) => ({
        ...error,
        registration_doc: `${tempRegistrationDoc.doc_name} Should not contain whitespace`,
      }));
      setValidData((validData) => ({
        ...validData,
        registration_doc: false,
      }));
      return false;
    }
    if (!alphaNumericRegexp.test(tempRegistrationDoc.doc_number)) {
      setError((error) => ({
        ...error,
        registration_doc: "Special character not allowed",
      }));
      setValidData((validData) => ({
        ...validData,
        registration_doc: false,
      }));
      return false;
    }
    return true;
  }
  function checkDirectorDocument() {
    if (!tempDirectorDoc.doc_name) {
      setError((error) => ({
        ...error,
        selected_director_doc: "please select a document type",
      }));
      setValidData((validData) => ({
        ...validData,
        selected_director_doc: false,
      }));
      return false;
    }
    if (checkIsEmpty(tempDirectorDoc.doc_number)) {
      setError((error) => ({
        ...error,
        director_doc: `${tempDirectorDoc.doc_name} number cannot be empty`,
      }));
      setValidData((validData) => ({
        ...validData,
        director_doc: false,
      }));
      return false;
    }
    if (tempDirectorDoc.doc_number.indexOf(" ") >= 0) {
      setError((error) => ({
        ...error,
        director_doc: `${tempDirectorDoc.doc_name} Should not contain whitespace`,
      }));
      setValidData((validData) => ({
        ...validData,
        director_doc: false,
      }));
      return false;
    }
    if (!alphaNumericRegexp.test(tempDirectorDoc.doc_number)) {
      setError((error) => ({
        ...error,
        director_doc: "Special character not allowed",
      }));
      setValidData((validData) => ({
        ...validData,
        director_doc: false,
      }));
      return false;
    }
    return true;
  }
  async function updateKycDetails() {
    const allCheckPasses = [
      checkGstinNumber(),
      checkRegistrationDocument(),
      checkDirectorDocument(),
      checkAuthSignatoryName(),
      checkAuthSignatoryDesignation(),
    ].every(Boolean);
    if (!allCheckPasses) {
      return;
    }
    setKycLoading(true);
    try {
      const response: any = await callPutApi(
        `/organisations/${org_id}?action=update_kyc_info`,
        {
          registration_doc: tempRegistrationDoc,
          director_doc: tempDirectorDoc,
          auth_signatory: tempAuthorisedSignatory,
          gstin: tempGSTINNumber.gstin,
        }
      );
      setKycLoading(false);
      setToggleUpdateKYCDetails(false);
      setToggleApproveAccountButton(true);
      const updatedOrg = response.data?.updatedOrg ?? {};
      const { kyc, onboarding_status } = updatedOrg;
      setOnboardingStatus(onboarding_status);
      onUpdateKycDetails(kyc);
      const { account_id, ifsc, bank_name } =
        response.data?.virtualAccount ?? {};
      const virtualAccountdeatils = {
        account_number: account_id,
        bank_name,
        ifsc,
      };
    } catch (err: any) {
      setKycLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message:
            err.response.data.err + " TraceId is \n" + apmTraceId(apmInstance),
          copy: apmTraceId(apmInstance),
        },
      });
    }
  }
  function changeToDefaultState() {
    setTempRegistrationDoc({
      doc_name: registrationDoc.doc_name ?? "",
      doc_number: registrationDoc.doc_number ?? "",
    });
    setTempGSTINNumber({
      gstin: gstin ?? "",
    });
    setTempDirectorDoc({
      doc_name: directorDoc.doc_name ?? "",
      doc_number: directorDoc.doc_number ?? "",
    });
    setTempAuthorisedSignatory({
      signatory_name: auth_signatory.signatory_name ?? "",
      signatory_designation: auth_signatory.signatory_designation ?? "",
    });
    return;
  }
  return (
    <div>
      <div className={sharedStyle.card}>
        <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
          <div className="d-flex align-items-center">
            <p className={`mb-0 ${sharedStyle.cardHeader}`}>KYC Details</p>
            <div className="ml-auto">
              {is_allowed_to_write ? (
                toggleUpdateKYCDetails ? (
                  <div className="d-flex align-items-center py-1">
                    <div className="px-2">
                      <Button
                        size={COMPRESSED}
                        style={cancelButtonStyle}
                        hoveredStyle={cancelButtonHoverStyle}
                        disabled={kycLoading}
                        onClick={() => {
                          changeToDefaultState();
                          setToggleUpdateKYCDetails(false);
                          setError({
                            selected_registration_doc: "",
                            registration_doc: "",
                            selected_director_doc: "",
                            director_doc: "",
                            gstin_number: "",
                            auth_signatory_name: "",
                            auth_signatory_designation: "",
                          });
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="px-2">
                      <Button
                        size={COMPRESSED}
                        style={successButtonStyle}
                        hoveredStyle={successButtonHoverStyle}
                        isLoading={kycLoading}
                        onClick={() => updateKycDetails()}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                ) : org_status === orgStatus.DISABLED ? null : (
                  <UpdateSvg
                    click={() => {
                      changeToDefaultState();
                      setToggleUpdateKYCDetails(true);
                    }}
                  />
                )
              ) : null}
            </div>
          </div>
        </div>
        {kycLoading || isLoading ? (
          <div
            style={{ height: "350px" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Loading loadingColor={ACCENTCOLOR} />
          </div>
        ) : (
          <div className="p-4 container-fluid">
            <div className="row">
              <div className="col-md-6">
                {toggleUpdateKYCDetails ? (
                  <div className={Styles.editKycDocumentWrapper}>
                    <InputDropdown
                      labelname="Registration Document"
                      optionsArray={documentType}
                      defaultValue={tempRegistrationDoc.doc_name}
                      hasError={error.selected_registration_doc}
                      isValid={validData.selected_registration_doc}
                      no_shadow="true"
                      click={(value: string) => {
                        setTempRegistrationDoc(
                          (selectedRegistrationDocument: any) => ({
                            ...selectedRegistrationDocument,
                            doc_name: value,
                            doc_number: "",
                          })
                        );
                        setError((error) => ({
                          ...error,
                          selected_registration_doc: "",
                        }));
                        setValidData((validData) => ({
                          ...validData,
                          selected_registration_doc: true,
                        }));
                      }}
                    />
                    {error.selected_registration_doc && (
                      <ErrorMessage>
                        {error.selected_registration_doc}
                      </ErrorMessage>
                    )}
                    <div className="py-2">
                      <input
                        type="text"
                        className={`${sharedStyle.commonInputClass} ${
                          error.registration_doc
                            ? sharedStyle.error
                            : validData.registration_doc
                            ? sharedStyle.valid
                            : sharedStyle.formControl
                        } shadow-none`}
                        name="registration_document"
                        id="registration_document"
                        autoComplete="off"
                        maxLength={
                          tempRegistrationDoc.doc_name === documentType[0]
                            ? 21
                            : 12
                        }
                        value={tempRegistrationDoc.doc_number}
                        placeholder={`Enter ${tempRegistrationDoc.doc_name} Number`}
                        onChange={(event: any) => {
                          setTempRegistrationDoc(
                            (selectedRegistrationDocument: any) => ({
                              ...selectedRegistrationDocument,
                              doc_number: event.target.value,
                            })
                          );
                          setError((error) => ({
                            ...error,
                            registration_doc: "",
                          }));
                          setValidData((validData) => ({
                            ...validData,
                            registration_doc: true,
                          }));
                        }}
                        onBlur={checkRegistrationDocument}
                      />
                      {error.registration_doc && (
                        <ErrorMessage>{error.registration_doc}</ErrorMessage>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="py-2">
                    <p className={`mb-0 ${Styles.key}`}>
                      Registration Document
                    </p>
                    {registrationDoc.doc_name && registrationDoc.doc_number ? (
                      <>
                        <p className={`mb-0 ${Styles.value}`}>
                          {registrationDoc.doc_name ?? "NA"}
                        </p>
                        <p className={`mb-0 ${Styles.value}`}>
                          {registrationDoc.doc_number ?? "NA"}
                        </p>
                      </>
                    ) : (
                      <p className={`mb-0 ${Styles.value}`}>NA</p>
                    )}
                  </div>
                )}
              </div>

              {/* Autorised Signatory Name */}
              <div className="col-md-6">
                {toggleUpdateKYCDetails ? (
                  <div className={Styles.editKycDocumentWrapper}>
                    <Input
                      type="text"
                      name="auth_signatory_name"
                      id="auth_signatory_name"
                      autoComplete="off"
                      hasError={error.auth_signatory_name}
                      isValid={validData.auth_signatory_name}
                      maxLength={50}
                      placeholder="Enter Authorised Signatory Name"
                      labelname="Authorised Signatory Name"
                      value={tempAuthorisedSignatory.signatory_name}
                      no_shadow="true"
                      onChange={(event: any) => {
                        setTempAuthorisedSignatory((signatoryDetails: any) => ({
                          ...signatoryDetails,
                          signatory_name: event.target.value,
                        }));
                        setError((error) => ({
                          ...error,
                          auth_signatory_name: "",
                        }));
                        setValidData((validData) => ({
                          ...validData,
                          auth_signatory_name: true,
                        }));
                      }}
                      onBlur={(event: any) => {
                        setTempAuthorisedSignatory((signatoryDetails: any) => ({
                          ...signatoryDetails,
                          signatory_name: event.target.value.trim(),
                        }));
                        checkAuthSignatoryName();
                      }}
                    />
                    {error.auth_signatory_name && (
                      <ErrorMessage>{error.auth_signatory_name}</ErrorMessage>
                    )}
                  </div>
                ) : (
                  <KeyValuePair
                    className="pt-3"
                    label="Authorised Signatory Name"
                    value={auth_signatory.signatory_name ?? "NA"}
                  />
                )}
              </div>

              <div className="col-md-6 py-2">
                {toggleUpdateKYCDetails ? (
                  <div className={Styles.editKycDocumentWrapper}>
                    <InputDropdown
                      labelname="Director Document"
                      optionsArray={directorDocumentType}
                      defaultValue={tempDirectorDoc.doc_name}
                      hasError={error.selected_director_doc}
                      isValid={validData.selected_director_doc}
                      no_shadow="true"
                      click={(value: string) => {
                        setTempDirectorDoc((selectedDirectorDocument: any) => ({
                          ...selectedDirectorDocument,
                          doc_name: value,
                          doc_number: "",
                        }));
                        setError((error) => ({
                          ...error,
                          selected_director_doc: "",
                        }));
                        setValidData((validData) => ({
                          ...validData,
                          selected_director_doc: true,
                        }));
                      }}
                    />
                    {error.selected_director_doc && (
                      <ErrorMessage>{error.selected_director_doc}</ErrorMessage>
                    )}
                    <div className="py-2">
                      <input
                        type="text"
                        className={`${sharedStyle.commonInputClass} ${
                          error.director_doc
                            ? sharedStyle.error
                            : validData.director_doc
                            ? sharedStyle.valid
                            : sharedStyle.formControl
                        } shadow-none`}
                        name="director_document"
                        id="director_document"
                        autoComplete="off"
                        maxLength={
                          tempDirectorDoc.doc_name === directorDocumentType[0]
                            ? 9
                            : 10
                        }
                        value={tempDirectorDoc.doc_number}
                        placeholder={`Enter ${tempDirectorDoc.doc_name} Number`}
                        onChange={(event: any) => {
                          setTempDirectorDoc(
                            (selectedDirectorDocument: any) => ({
                              ...selectedDirectorDocument,
                              doc_number: event.target.value,
                            })
                          );
                          setError((error) => ({
                            ...error,
                            director_doc: "",
                          }));
                          setValidData((validData) => ({
                            ...validData,
                            director_doc: true,
                          }));
                        }}
                        onBlur={checkDirectorDocument}
                      />
                      {error.director_doc && (
                        <ErrorMessage>{error.director_doc}</ErrorMessage>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="py-2">
                    <p className={`mb-0 ${Styles.key}`}>Director Details</p>
                    {directorDoc.doc_name && directorDoc.doc_number ? (
                      <>
                        <p className={`mb-0 ${Styles.value}`}>
                          {directorDoc.doc_name ?? "NA"}
                        </p>
                        <p className={`mb-0 ${Styles.value}`}>
                          {directorDoc.doc_number ?? "NA"}
                        </p>
                      </>
                    ) : (
                      <p className={`mb-0 ${Styles.value}`}>NA</p>
                    )}
                  </div>
                )}
              </div>

              {/* Autorised Signatory Designation */}
              <div className="col-md-6 py-0">
                {toggleUpdateKYCDetails ? (
                  <div className={Styles.editKycDocumentWrapper}>
                    <Input
                      type="text"
                      name="auth_signatory_designation"
                      id="auth_signatory_designation"
                      autoComplete="off"
                      hasError={error.auth_signatory_designation}
                      isValid={validData.auth_signatory_designation}
                      maxLength={50}
                      placeholder="Enter Authorised Signatory Designation"
                      labelname="Authorised Signatory Designation"
                      value={tempAuthorisedSignatory.signatory_designation}
                      no_shadow="true"
                      onChange={(event: any) => {
                        setTempAuthorisedSignatory((signatoryDetails: any) => ({
                          ...signatoryDetails,
                          signatory_designation: event.target.value,
                        }));
                        setError((error) => ({
                          ...error,
                          auth_signatory_designation: "",
                        }));
                        setValidData((validData) => ({
                          ...validData,
                          auth_signatory_designation: true,
                        }));
                      }}
                      onBlur={(event: any) => {
                        setTempAuthorisedSignatory((signatoryDetails: any) => ({
                          ...signatoryDetails,
                          signatory_designation: event.target.value.trim(),
                        }));
                        checkAuthSignatoryDesignation();
                      }}
                    />
                    {error.auth_signatory_designation && (
                      <ErrorMessage>
                        {error.auth_signatory_designation}
                      </ErrorMessage>
                    )}
                  </div>
                ) : (
                  <KeyValuePair
                    className="pt-3"
                    label="Authorised Signatory Designation"
                    value={auth_signatory.signatory_designation ?? "NA"}
                  />
                )}
              </div>

              <div className="col-md-6 py-2">
                {toggleUpdateKYCDetails ? (
                  <div className={Styles.editKycDocumentWrapper}>
                    <Input
                      type="text"
                      name="gstin_number"
                      id="gstin_number"
                      autoComplete="off"
                      hasError={error.gstin_number}
                      isValid={validData.gstin_number}
                      placeholder="Enter GSTIN Number"
                      labelname="GSTIN Number"
                      value={tempGSTINNumber.gstin}
                      no_shadow="true"
                      onChange={(event: any) => {
                        setTempGSTINNumber({ gstin: event.target.value });
                        setError((error) => ({
                          ...error,
                          gstin_number: "",
                        }));
                        setValidData((validData) => ({
                          ...validData,
                          gstin_number: true,
                        }));
                      }}
                      onBlur={checkGstinNumber}
                    />
                    {error.gstin_number && (
                      <ErrorMessage>{error.gstin_number}</ErrorMessage>
                    )}
                  </div>
                ) : (
                  <KeyValuePair
                    className="pt-3"
                    label="GSTIN Number"
                    value={gstin ?? "NA"}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {toggleApproveAccountButton &&
        onboardingStatus === "ACTIVATION_REQUESTED" && (
          <ApproveKycDetailsModal
            message={
              "Please approve the KYC details of the organisation to activate their account."
            }
            org_id={org_id}
            onClosePopUp={() => setToggleApproveAccountButton(false)}
            onApprove={(virtualAccount: any) => {
              dispatch({
                type: "ADD_TOAST",
                payload: {
                  id: Math.floor(Math.random() * 100),
                  type: SUCCESS,
                  message: "Account has been approved.",
                },
              });
            }}
          />
        )}
    </div>
  );
}

export default InternationalCustomers;

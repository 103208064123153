import React, { useContext, useEffect } from "react";
import Styles from "../home.module.scss";
import sharedStyle from "../../../../shared/sharedStyle.module.scss";
import { Link } from "react-router-dom";
import Loading from "../../../../shared/loading/loading";
import { ACCENTCOLOR } from "../../../../shared/colors";
import { callGetApi } from "../../../../api/axios";
import { durationStyles } from "../../../../constants/durationStyles";
import { getDuration } from "../../../../utils/getDuration";
import { getDurationStyle } from "../../../../utils/getDurationStyle";
import { monthName } from "../../../../utils/getMonth";
import { ERROR } from "../../../../utils/toastType";
import { ToastContext } from "../../../../context/toastContext";
import { ApmContext } from "../../../../utils/apmProvider";

function ProductPendingApprovalCard() {
  const [pendingProductApprovalLoading, setPendingProductApprovalLoading] =
    React.useState(false);
  const [pendingProductList, setPendingProductList] = React.useState([]);
  const dispatch = useContext(ToastContext);
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  useEffect(() => {
    async function getPendingProductApproval() {
      setPendingProductApprovalLoading(true);
      try {
        const response: any = await callGetApi(
          "/organisations?status=product_pending_approval&per_page=15&page_no=1"
        );
        const pendingList = response.data.map((data: any) => ({
          name: data.name,
          products_requested: data.products_requested,
          requested_on: data.requested_on,
          id: data._id,
        }));

        const sortList = pendingList?.length
          ? pendingList.sort((a: any, b: any) => {
              return b.requested_on - a.requested_on;
            })
          : [];

        setPendingProductApprovalLoading(false);
        setPendingProductList(sortList);
      } catch (error: any) {
        setPendingProductApprovalLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message:
              error.response.data.err +
              " TraceId is \n" +
              apmTraceId(apmInstance),
            copy: apmTraceId(apmInstance),
          },
        });
      }
    }
    getPendingProductApproval();
  }, [dispatch]);
  return (
    <div className="col-lg-6 p-3">
      <div className={sharedStyle.card}>
        <div className={`px-4 pt-4 ${sharedStyle.cardHeaderBack}`}>
          <div className="d-flex align-items-center pb-3 justify-content-between">
            <p className={`mb-0 ${sharedStyle.cardHeader}`}>
              Pending Product Approvals
            </p>
            <div className="px-2 ml-auto">
              <Link
                to={"/application/home/pending-products"}
                className={`mb-0 ${Styles.link}`}
              >
                View More
              </Link>
            </div>
          </div>
        </div>
        {pendingProductApprovalLoading ? (
          <div
            style={{ height: "300px" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Loading loadingColor={ACCENTCOLOR} />
          </div>
        ) : pendingProductList.length > 0 ? (
          <div style={{ overflowX: "auto" }}>
            <table style={{ width: "100%" }}>
              <thead className={sharedStyle.tableHeaderBack}>
                <tr>
                  <th className={Styles.account_name_column}>
                    <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                      Account Name
                    </p>
                  </th>
                  <th className={Styles.service_requested}>
                    <p
                      className={`mb-0 ${sharedStyle.tableHeaderText} text-center`}
                    >
                      Service Requested
                    </p>
                  </th>
                  <th className={Styles.date}>
                    <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                      Date
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody className={Styles.tableWrapper}>
                {pendingProductList
                  .slice(0, 15)
                  .map((account: any, index: number) => {
                    const date = new Date(Number(account.requested_on));
                    const duration = getDuration(date);
                    const style = getDurationStyle(duration, 7, 3);
                    return (
                      <tr
                        key={index}
                        style={
                          index !== pendingProductList.length - 1
                            ? { borderBottom: "1px solid #eceeef" }
                            : { borderBottom: 0 }
                        }
                      >
                        <td className={Styles.account_name_column}>
                          <Link
                            className={`mb-0 ${Styles.accountName}`}
                            to={`/application/accounts/${account.id}`}
                          >
                            {account.name}
                          </Link>
                        </td>
                        <td
                          className={`${Styles.service_requested} text-center`}
                        >
                          <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                            {account.products_requested.length}
                          </p>
                        </td>
                        <td className={Styles.date}>
                          <div
                            className={`mb-0 ${Styles.duration}`}
                            style={
                              style === durationStyles.DANGER
                                ? { color: "#d64747" }
                                : style === durationStyles.WARNING
                                ? { color: "#f4a63b" }
                                : { color: "#31b975" }
                            }
                          >
                            {duration}
                          </div>
                          <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                            {`${monthName(
                              date.getMonth()
                            )} ${date.getDate()}, ${date.getFullYear()}`}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            style={{ height: "300px" }}
            className="d-flex align-items-center justify-content-center"
          >
            <p className="mb-0">No Account Found</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductPendingApprovalCard;

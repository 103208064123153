import React, { useEffect, useRef } from "react";
import Styles from "./toast.module.scss";
import TickSvg from "../svg/tickSvg";
import Close from "../svg/close";
import { SUCCESS, DANGER, WHITE } from "../colors";
import { ERROR } from "../../utils/toastType";
import CopySVG from "./copySVG";

function Toast({ click, type, message, time, copy }: any) {
  const [exitToast, setExitToast] = React.useState<Boolean>(false);
  const [copiedText, setCopiedText] = React.useState<Boolean>(false);
  const requestBoxRef = useRef<any>("");

  useEffect(() => {
    if (time) return;
    setTimeout(() => {
      setExitToast(true);
      click();
    }, 5000);
  }, [click]);

  async function copyText() {
    try {
      if (copy) {
        await navigator.clipboard.writeText(copy);
      }

      setCopiedText(true);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div
      className={`${Styles.toastPosition} ${
        exitToast ? Styles.slideOut : ""
      } d-flex align-items-center`}
      style={{
        borderLeft: `12px solid ${type === ERROR ? DANGER : SUCCESS}`,
      }}
    >
      <div className="px-2">
        <div
          className={`d-flex align-items-center justify-content-center ${Styles.iconBackground}`}
          style={{ background: type === ERROR ? DANGER : SUCCESS }}
        >
          {type === ERROR ? (
            <Close width="15" height="15" classes={Styles.errorSvgColor} />
          ) : (
            <TickSvg width="20" height="20" color={WHITE} margin="5px" />
          )}
        </div>
      </div>
      <div className="px-2 flex-grow-1 d-flex">
        <p className={`mb-0 ${Styles.messageText}`} ref={requestBoxRef}>
          {message}
        </p>
        {copy && (
          <p
            onClick={copyText}
            className={Styles.copyButton}
            style={{
              marginTop: "25px",
              height: "35px",
              borderColor: "white",
              backgroundColor: "white",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            <CopySVG />
            <p className={Styles.copyText}>
              {copiedText ? "Copied" : "Copy text"}
            </p>
          </p>
        )}
      </div>
      <div className="px-2">
        <Close
          width="10"
          height="10"
          classes={Styles.overAllCrossSvg}
          onClick={() => {
            setExitToast(true);
            click();
          }}
        />
      </div>
    </div>
  );
}

export default Toast;

import React, { useCallback, useContext, useEffect } from "react";
import { callGetApi } from "../../../../../api/axios";
import { ERROR } from "../../../../../utils/toastType";
import { useParams } from "react-router-dom";
import CurrentPlanCard from "./current-plan-card/currentPlanCard";
import RequestedPlan from "./requested-plan/requestedPlan";
import AdvanceReceipts from "./advance-receipts/advanceReceipts";
import { ToastContext } from "../../../../../context/toastContext";
import PlanHistory from "./plan-history/planhistory";
import { ApmContext } from "../../../../../utils/apmProvider";
// import UtilisationReports from "./utilisation-reports/utilisationReports";

function Billing({
  // org_name,
  org_status,
  onUpdateAmount,
  getUpdateAccountInfo,
  alert_balance,
  country_code,
  is_allowed_to_write,
}: any) {
  const { id }: any = useParams();
  const [currentPlan, setCurrentPlan] = React.useState<any>();
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  const [requestedPlan, setRequestedPlan] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useContext(ToastContext);
  const getBillingInfoOfOrg = useCallback(async () => {
    setLoading(true);
    try {
      const response: any = await callGetApi(
        `organisations/${id}?data=billing_info`
      );
      setLoading(false);
      const { current_plan, requested_plan, wallet_balance } = response.data;
      setCurrentPlan((currentPlan: any) => ({
        ...currentPlan,
        ...current_plan,
        wallet_balance: wallet_balance,
      }));
      setRequestedPlan(requested_plan);
    } catch (err: any) {
      setLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message:
            err.response.data.err + " TraceId is \n" + apmTraceId(apmInstance),
          copy: apmTraceId(apmInstance),
        },
      });
    }
  }, [id, dispatch]);

  useEffect(() => {
    getBillingInfoOfOrg();
  }, [getBillingInfoOfOrg]);

  return (
    <div className="py-3 container-fluid">
      <div className="row">
        <div className={requestedPlan ? "col-lg-6 py-3" : "col-md-12 py-3"}>
          <CurrentPlanCard
            id={id}
            org_status={org_status}
            current_plan={currentPlan}
            isLoading={loading}
            getUpdatedBillingInfo={() => getBillingInfoOfOrg()}
            getUpdateAccountInfo={() => getUpdateAccountInfo()}
            alert_balance={alert_balance}
            is_allowed_to_write={is_allowed_to_write}
          />
        </div>
        {requestedPlan && (
          <div className="col-lg-6 py-3">
            <RequestedPlan
              requested_plan={requestedPlan}
              isLoading={loading}
              wallet_balance={currentPlan.wallet_balance}
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-12 py-3">
          <PlanHistory
            id={id}
            getUpdateAccountInfo={() => getUpdateAccountInfo()}
          />
        </div>
        <div className="col-md-12 py-3">
          <AdvanceReceipts
            id={id}
            org_status={org_status}
            country_code={country_code}
            planSubscribed={{
              currentPlan: currentPlan,
              requestedPlan: requestedPlan,
            }}
            getUpdatedBillingInfo={() => getBillingInfoOfOrg()}
            onUpdateAmount={(value: number) => onUpdateAmount(value)}
            getUpdateAccountInfo={() => getUpdateAccountInfo()}
            is_allowed_to_write={is_allowed_to_write}
          />
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-12 py-3">
          <UtilisationReports
            id={id}
            org_name={org_name}
          />
        </div>
      </div> */}
    </div>
  );
}

export default Billing;

import React, { useContext } from "react";
import Styles from "../kycDetails.module.scss";
import sharedStyle from "../../../../../../shared/sharedStyle.module.scss";
import { documentType } from "../../../../../../constants/registrationDocumentTypes";
import Button from "../../../../../../shared/button/button";
import { COMPRESSED } from "../../../../../../shared/buttonSize";
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  successButtonHoverStyle,
  successButtonStyle,
} from "../../../../../../shared/buttonStyles";
import InputDropdown from "../../../../../../shared/input-dropdown/inputDropdown";
import UpdateSvg from "../../../../../../shared/svg/updateSvg";
import Input from "../../../../../../shared/input/input";
import ErrorMessage from "../../../../../../shared/error-message/errorMessage";
import KeyValuePair from "../../../../../../shared/key-value-pair/keyValuePair";
import Loading from "../../../../../../shared/loading/loading";
import { ACCENTCOLOR } from "../../../../../../shared/colors";
import { callPutApi } from "../../../../../../api/axios";
import { ERROR, SUCCESS } from "../../../../../../utils/toastType";
import {
  checkIsEmpty,
  isNumeric,
  isValidGSTIN,
  isValidName,
} from "../../../../../../utils/validation";
import { ToastContext } from "../../../../../../context/toastContext";
import ApproveKycDetailsModal from "../approve-kyc-details-modal/approveKycDetailsModal";
import { orgStatus } from "../../../../../../constants/orgStatus";
import { ApmContext } from "../../../../../../utils/apmProvider";
function NationalCustomer({
  bankAccountInfo = {},
  auth_signatory = {},
  registrationDoc = {},
  gstin,
  isLoading,
  org_id,
  org_status,
  onUpdateKycDetails,
  onboarding_status,
  updatedVirtualAccount,
  is_allowed_to_write,
}: any) {
  const [kycLoading, setKycLoading] = React.useState(isLoading);
  const [onboardingStatus, setOnboardingStatus] =
    React.useState(onboarding_status);
  const [toggleUpdateKYCDetails, setToggleUpdateKYCDetails] =
    React.useState(false);
  const dispatch = useContext(ToastContext);
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  const [tempGSTINNumber, setTempGSTINNumber] = React.useState({
    gstin: gstin ?? "",
  });
  const [tempRegistrationDoc, setTempRegistrationDoc] = React.useState({
    doc_name: registrationDoc.doc_name ?? "",
    doc_number: registrationDoc.doc_number ?? "",
  });
  const [tempAuthorisedSignatory, setTempAuthorisedSignatory] = React.useState({
    signatory_name: auth_signatory.signatory_name ?? "",
    signatory_designation: auth_signatory.signatory_designation ?? "",
  });
  const [tempBankAccountInfo, setTempBankAccountInfo] = React.useState({
    account_no: bankAccountInfo.account_no ?? "",
    bank_name: bankAccountInfo.bank_name ?? "",
    ifsc_code: bankAccountInfo.ifsc_code ?? "",
  });
  const [error, setError] = React.useState({
    gstin_number: "",
    selected_registration_doc: "",
    registration_doc_number: "",
    account_number: "",
    bank_name: "",
    ifsc_code: "",
    auth_signatory_name: "",
    auth_signatory_designation: "",
  });
  const [validData, setValidData] = React.useState({
    gstin_number: false,
    selected_registration_doc: false,
    registration_doc_number: false,
    account_number: false,
    bank_name: false,
    ifsc_code: false,
    auth_signatory_name: false,
    auth_signatory_designation: false,
  });
  const [toggleApproveAccountButton, setToggleApproveAccountButton] =
    React.useState(false);
  // const ifscRegexp = /^[A-Za-z]{4}\d{7}$/;
  const bankAccountNumberRegexp = /^\d{9,18}$/;
  const alphaNumericRegexp = /^[a-zA-Z0-9]+$/;
  const alphaNumericNameRegexp = /^[a-zA-Z0-9 ]+$/;
  function checkGstinNumber() {
    if (checkIsEmpty(tempGSTINNumber.gstin)) {
      setError((error) => ({
        ...error,
        gstin_number: "GSTIN number cannot be empty",
      }));
      setValidData((validData) => ({
        ...validData,
        gstin_number: false,
      }));
      return false;
    }
    if (
      !isValidGSTIN(tempGSTINNumber.gstin) ||
      tempGSTINNumber.gstin.length > 15
    ) {
      setError((error) => ({
        ...error,
        gstin_number: "Enter a valid GSTIN number",
      }));
      setValidData((validData) => ({
        ...validData,
        gstin_number: false,
      }));
      return false;
    }
    return true;
  }
  function checkAuthSignatoryName() {
    if (checkIsEmpty(tempAuthorisedSignatory.signatory_name)) {
      setError((error) => ({
        ...error,
        auth_signatory_name: "Please enter a Authorised Signatory name",
      }));
      setValidData((validData) => ({
        ...validData,
        auth_signatory_name: false,
      }));
      return false;
    }
    if (!alphaNumericNameRegexp.test(tempAuthorisedSignatory.signatory_name)) {
      setError((error) => ({
        ...error,
        auth_signatory_name: "Special character not allowed",
      }));
      setValidData((validData) => ({
        ...validData,
        auth_signatory_name: false,
      }));
      return false;
    }
    return true;
  }
  function checkAuthSignatoryDesignation() {
    if (checkIsEmpty(tempAuthorisedSignatory.signatory_designation)) {
      setError((error) => ({
        ...error,
        auth_signatory_designation:
          "Please enter Authorised Signatory Designation",
      }));
      setValidData((validData) => ({
        ...validData,
        auth_signatory_designation: false,
      }));
      return false;
    }
    if (
      !alphaNumericNameRegexp.test(
        tempAuthorisedSignatory.signatory_designation
      )
    ) {
      setError((error) => ({
        ...error,
        auth_signatory_designation: "Special character not allowed",
      }));
      setValidData((validData) => ({
        ...validData,
        auth_signatory_designation: false,
      }));
      return false;
    }
    return true;
  }
  function checkRegistrationDocument() {
    if (!tempRegistrationDoc.doc_name) {
      setError((error) => ({
        ...error,
        selected_registration_doc: "please select a document type",
      }));
      setValidData((validData) => ({
        ...validData,
        selected_registration_doc: false,
      }));
      return false;
    }
    if (checkIsEmpty(tempRegistrationDoc.doc_number)) {
      setError((error) => ({
        ...error,
        registration_doc_number: `${tempRegistrationDoc.doc_name} number cannot be empty`,
      }));
      setValidData((validData) => ({
        ...validData,
        registration_doc_number: false,
      }));
      return false;
    }
    if (tempRegistrationDoc.doc_number.indexOf(" ") >= 0) {
      setError((error) => ({
        ...error,
        registration_doc_number: `${tempRegistrationDoc.doc_name} should not contain whitespace`,
      }));
      setValidData((validData) => ({
        ...validData,
        registration_doc_number: false,
      }));
      return false;
    }
    if (!alphaNumericRegexp.test(tempRegistrationDoc.doc_number)) {
      setError((error) => ({
        ...error,
        registration_doc_number: "Special character not allowed",
      }));
      setValidData((validData) => ({
        ...validData,
        registration_doc_number: false,
      }));
      return false;
    }
    return true;
  }
  function checkAccountNumber() {
    if (checkIsEmpty(tempBankAccountInfo.account_no)) {
      setError((error) => ({
        ...error,
        account_number: "Account number cannot be empty",
      }));
      setValidData((validData) => ({
        ...validData,
        account_number: false,
      }));
      return false;
    }
    if (!isNumeric(tempBankAccountInfo.account_no)) {
      setError((error) => ({
        ...error,
        account_number: "Account number Should only be numeric",
      }));
      setValidData((validData) => ({
        ...validData,
        account_number: false,
      }));
      return false;
    }
    if (!bankAccountNumberRegexp.test(tempBankAccountInfo.account_no)) {
      setError((error) => ({
        ...error,
        account_number: "Enter a valid account number",
      }));
      setValidData((validData) => ({
        ...validData,
        account_number: false,
      }));
      return false;
    }
    return true;
  }
  function checkBankName() {
    if (checkIsEmpty(tempBankAccountInfo.bank_name)) {
      setError((error) => ({
        ...error,
        bank_name: "Bank Name cannot be empty",
      }));
      setValidData((validData) => ({
        ...validData,
        bank_name: false,
      }));
      return false;
    }
    if (isValidName(tempBankAccountInfo.bank_name, false)) {
      setError((error) => ({
        ...error,
        bank_name: "Enter a valid bank name",
      }));
      setValidData((validData) => ({
        ...validData,
        bank_name: false,
      }));
      return false;
    }
    return true;
  }
  function checkIfsc() {
    if (checkIsEmpty(tempBankAccountInfo.ifsc_code)) {
      setError((error) => ({
        ...error,
        ifsc_code: "IFSC code cannot be empty",
      }));
      setValidData((validData) => ({
        ...validData,
        ifsc_code: false,
      }));
      return false;
    }
    if (!alphaNumericRegexp.test(tempBankAccountInfo.ifsc_code)) {
      setError((error) => ({
        ...error,
        ifsc_code: "Enter a valid IFSC code",
      }));
      setValidData((validData) => ({
        ...validData,
        ifsc_code: false,
      }));
      return false;
    }
    return true;
  }
  async function updateKycDetails() {
    const allCheckPasses = [
      checkGstinNumber(),
      checkRegistrationDocument(),
      checkAuthSignatoryName(),
      checkAuthSignatoryDesignation(),
      checkAccountNumber(),
      checkBankName(),
      checkIfsc(),
    ].every(Boolean);
    if (!allCheckPasses) {
      return;
    }
    setKycLoading(true);
    try {
      const response: any = await callPutApi(
        `/organisations/${org_id}?action=update_kyc_info`,
        {
          registration_doc: tempRegistrationDoc,
          bank_account: tempBankAccountInfo,
          auth_signatory: tempAuthorisedSignatory,
          gstin: tempGSTINNumber.gstin,
        }
      );
      setKycLoading(false);
      setToggleUpdateKYCDetails(false);
      setToggleApproveAccountButton(true);
      const updatedOrg = response.data?.updatedOrg ?? {};
      const { kyc, onboarding_status } = updatedOrg;
      setOnboardingStatus(onboarding_status);
      onUpdateKycDetails(kyc);

      const { account_number, ifsc, bank_name } =
        response.data?.virtualAccount ?? {};
      const virtualAccountdeatils = {
        account_number,
        bank_name,
        ifsc,
      };
    } catch (err: any) {
      setKycLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message:
            err.response.data.err + " TraceId is \n" + apmTraceId(apmInstance),
          copy: apmTraceId(apmInstance),
        },
      });
    }
  }
  function changeToDefaultState() {
    setTempRegistrationDoc({
      doc_name: registrationDoc.doc_name ?? "",
      doc_number: registrationDoc.doc_number ?? "",
    });
    setTempBankAccountInfo({
      account_no: bankAccountInfo.account_no ?? "",
      bank_name: bankAccountInfo.bank_name ?? "",
      ifsc_code: bankAccountInfo.ifsc_code ?? "",
    });
    setTempAuthorisedSignatory({
      signatory_name: auth_signatory.signatory_name ?? "",
      signatory_designation: auth_signatory.signatory_designation ?? "",
    });
    setTempGSTINNumber({
      gstin: gstin ?? "",
    });
    return;
  }
  return (
    <div>
      <div className={sharedStyle.card}>
        <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
          <div className="d-flex align-items-center">
            <p className={`mb-0 ${sharedStyle.cardHeader}`}>KYC Details</p>
            <div className="ml-auto">
              {is_allowed_to_write ? (
                toggleUpdateKYCDetails ? (
                  <div className="d-flex align-items-center py-1">
                    <div className="px-2">
                      <Button
                        size={COMPRESSED}
                        style={cancelButtonStyle}
                        hoveredStyle={cancelButtonHoverStyle}
                        disabled={kycLoading}
                        onClick={() => {
                          changeToDefaultState();
                          setToggleUpdateKYCDetails(false);
                          setError({
                            gstin_number: "",
                            selected_registration_doc: "",
                            registration_doc_number: "",
                            account_number: "",
                            bank_name: "",
                            ifsc_code: "",
                            auth_signatory_name: "",
                            auth_signatory_designation: "",
                          });
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="px-2">
                      <Button
                        size={COMPRESSED}
                        style={successButtonStyle}
                        disabled={kycLoading}
                        hoveredStyle={successButtonHoverStyle}
                        onClick={() => updateKycDetails()}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                ) : org_status === orgStatus.DISABLED ? null : (
                  <UpdateSvg
                    click={() => {
                      changeToDefaultState();
                      setToggleUpdateKYCDetails(true);
                    }}
                  />
                )
              ) : null}
            </div>
          </div>
        </div>
        {kycLoading || isLoading ? (
          <div
            style={{ height: "350px" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Loading loadingColor={ACCENTCOLOR} />
          </div>
        ) : (
          <div className="p-4 container-fluid">
            <div className="row">
              <div className="col-md-6">
                {toggleUpdateKYCDetails ? (
                  <div className={Styles.editKycDocumentWrapper}>
                    <Input
                      type="text"
                      name="gstin_number"
                      id="gstin_number"
                      autoComplete="off"
                      hasError={error.gstin_number}
                      isValid={validData.gstin_number}
                      placeholder="Enter GSTIN Number"
                      labelname="GSTIN Number"
                      value={tempGSTINNumber.gstin}
                      no_shadow="true"
                      onChange={(event: any) => {
                        setTempGSTINNumber({
                          gstin: event.target.value,
                        });
                        setError((error) => ({
                          ...error,
                          gstin_number: "",
                        }));
                        setValidData((validData) => ({
                          ...validData,
                          gstin_number: true,
                        }));
                      }}
                      onBlur={checkGstinNumber}
                    />
                    {error.gstin_number && (
                      <ErrorMessage>{error.gstin_number}</ErrorMessage>
                    )}
                  </div>
                ) : (
                  <KeyValuePair
                    className="pt-2"
                    label="GSTIN Number"
                    value={gstin ?? "NA"}
                  />
                )}
              </div>

              <div className="col-md-6">
                {toggleUpdateKYCDetails ? (
                  <div className={Styles.editKycDocumentWrapper}>
                    <Input
                      type="text"
                      name="auth_signatory_name"
                      id="auth_signatory_name"
                      autoComplete="off"
                      hasError={error.auth_signatory_name}
                      maxLength={50}
                      isValid={validData.auth_signatory_name}
                      placeholder="Enter Authorised Signatory Name"
                      labelname="Authorised Signatory Name"
                      value={tempAuthorisedSignatory.signatory_name}
                      no_shadow="true"
                      onChange={(event: any) => {
                        setTempAuthorisedSignatory((signatoryDetails: any) => ({
                          ...signatoryDetails,
                          signatory_name: event.target.value,
                        }));
                        setError((error) => ({
                          ...error,
                          auth_signatory_name: "",
                        }));
                        setValidData((validData) => ({
                          ...validData,
                          auth_signatory_name: true,
                        }));
                      }}
                      onBlur={(event: any) => {
                        setTempAuthorisedSignatory((signatoryDetails: any) => ({
                          ...signatoryDetails,
                          signatory_name: event.target.value.trim(),
                        }));
                        checkAuthSignatoryName();
                      }}
                    />
                    {error.auth_signatory_name && (
                      <ErrorMessage>{error.auth_signatory_name}</ErrorMessage>
                    )}
                  </div>
                ) : (
                  <KeyValuePair
                    className="pt-2"
                    label="Authorised Signatory Name"
                    value={auth_signatory.signatory_name ?? "NA"}
                  />
                )}
              </div>

              <div className="col-md-6 py-2">
                {toggleUpdateKYCDetails ? (
                  <div className={Styles.editKycDocumentWrapper}>
                    <InputDropdown
                      labelname="Registration Document"
                      optionsArray={documentType}
                      defaultValue={tempRegistrationDoc.doc_name}
                      no_shadow="true"
                      hasError={error.selected_registration_doc}
                      isValid={validData.selected_registration_doc}
                      click={(value: string) => {
                        setTempRegistrationDoc(
                          (selectedRegistrationDocument: any) => ({
                            ...selectedRegistrationDocument,
                            doc_name: value,
                            doc_number: "",
                          })
                        );
                        setError((error) => ({
                          ...error,
                          selected_registration_doc: "",
                        }));
                        setValidData((validData) => ({
                          ...validData,
                          selected_registration_doc: true,
                        }));
                      }}
                    />
                    {error.selected_registration_doc && (
                      <ErrorMessage>
                        {error.selected_registration_doc}
                      </ErrorMessage>
                    )}
                    <div className="py-2">
                      <input
                        type="text"
                        className={`${sharedStyle.commonInputClass} ${
                          error.registration_doc_number
                            ? sharedStyle.error
                            : validData.registration_doc_number
                            ? sharedStyle.valid
                            : sharedStyle.formControl
                        } shadow-none`}
                        name="registration_document"
                        id="registration_document"
                        autoComplete="off"
                        maxLength={
                          tempRegistrationDoc.doc_name === documentType[0]
                            ? 21
                            : 12
                        }
                        value={tempRegistrationDoc.doc_number}
                        placeholder={`Enter ${tempRegistrationDoc.doc_name} Number`}
                        onChange={(event: any) => {
                          setTempRegistrationDoc(
                            (selectedRegistrationDocument: any) => ({
                              ...selectedRegistrationDocument,
                              doc_number: event.target.value,
                            })
                          );
                          setError((error) => ({
                            ...error,
                            registration_doc_number: "",
                          }));
                          setValidData((validData) => ({
                            ...validData,
                            registration_doc_number: true,
                          }));
                        }}
                        onBlur={checkRegistrationDocument}
                      />
                      {error.registration_doc_number && (
                        <ErrorMessage>
                          {error.registration_doc_number}
                        </ErrorMessage>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="py-3">
                    <p className={`mb-0 ${Styles.key}`}>
                      Registration Document
                    </p>
                    {registrationDoc.doc_name && registrationDoc.doc_number ? (
                      <>
                        <p className={`mb-0 ${Styles.value}`}>
                          {registrationDoc.doc_name ?? "NA"}
                        </p>
                        <p className={`mb-0 ${Styles.value}`}>
                          {registrationDoc.doc_number ?? "NA"}
                        </p>
                      </>
                    ) : (
                      <p className={`mb-0 ${Styles.value}`}>NA</p>
                    )}
                  </div>
                )}
              </div>

              <div className="col-md-6 py-3">
                {toggleUpdateKYCDetails ? (
                  <div className={Styles.editKycDocumentWrapper}>
                    <Input
                      type="text"
                      name="auth_signatory_designation"
                      id="auth_signatory_designation"
                      autoComplete="off"
                      hasError={error.auth_signatory_designation}
                      isValid={validData.auth_signatory_designation}
                      maxLength={50}
                      placeholder="Enter Authorised Signatory Designation"
                      labelname="Authorised Signatory Designation"
                      value={tempAuthorisedSignatory.signatory_designation}
                      no_shadow="true"
                      onChange={(event: any) => {
                        setTempAuthorisedSignatory((signatoryDetails: any) => ({
                          ...signatoryDetails,
                          signatory_designation: event.target.value,
                        }));
                        setError((error) => ({
                          ...error,
                          auth_signatory_designation: "",
                        }));
                        setValidData((validData) => ({
                          ...validData,
                          auth_signatory_designation: true,
                        }));
                      }}
                      onBlur={(event: any) => {
                        setTempAuthorisedSignatory((signatoryDetails: any) => ({
                          ...signatoryDetails,
                          signatory_designation: event.target.value.trim(),
                        }));
                        checkAuthSignatoryDesignation();
                      }}
                    />
                    {error.auth_signatory_designation && (
                      <ErrorMessage>
                        {error.auth_signatory_designation}
                      </ErrorMessage>
                    )}
                  </div>
                ) : (
                  <KeyValuePair
                    className="pt-2"
                    label="Authorised Signatory Designation"
                    value={auth_signatory.signatory_designation ?? "NA"}
                  />
                )}
              </div>
              <div className="col-md-6 py-2">
                {toggleUpdateKYCDetails ? (
                  <div className={Styles.editKycDocumentWrapper}>
                    <Input
                      type="text"
                      name="account_number"
                      id="account_number"
                      autoComplete="off"
                      hasError={error.account_number}
                      isValid={validData.account_number}
                      placeholder="Enter Account Number"
                      labelname="Account Number"
                      maxLength={18}
                      value={tempBankAccountInfo.account_no}
                      no_shadow="true"
                      onChange={(event: any) => {
                        setTempBankAccountInfo((bankAccountInfo: any) => ({
                          ...bankAccountInfo,
                          account_no: event.target.value,
                        }));
                        setError((error) => ({
                          ...error,
                          account_number: "",
                        }));
                        setValidData((validData) => ({
                          ...validData,
                          account_number: true,
                        }));
                      }}
                      onBlur={checkAccountNumber}
                    />
                    {error.account_number && (
                      <ErrorMessage>{error.account_number}</ErrorMessage>
                    )}
                  </div>
                ) : (
                  <KeyValuePair
                    className="pt-2"
                    label="Account Number"
                    value={bankAccountInfo.account_no ?? "NA"}
                  />
                )}
              </div>

              <div
                className={`col-md-6 ${
                  toggleUpdateKYCDetails ? "py-2" : "py-2"
                }`}
              >
                {toggleUpdateKYCDetails ? (
                  <div className={Styles.editKycDocumentWrapper}>
                    <Input
                      type="text"
                      name="bank_name"
                      id="bank_name"
                      autoComplete="off"
                      hasError={error.bank_name}
                      isValid={validData.bank_name}
                      placeholder="Enter Bank Name"
                      labelname="Bank Name"
                      value={tempBankAccountInfo.bank_name}
                      no_shadow="true"
                      onChange={(event: any) => {
                        setTempBankAccountInfo((bankAccountInfo: any) => ({
                          ...bankAccountInfo,
                          bank_name: event.target.value,
                        }));
                        setError((error) => ({
                          ...error,
                          bank_name: "",
                        }));
                        setValidData((validData) => ({
                          ...validData,
                          bank_name: true,
                        }));
                      }}
                      onBlur={checkBankName}
                    />
                    {error.bank_name && (
                      <ErrorMessage>{error.bank_name}</ErrorMessage>
                    )}
                  </div>
                ) : (
                  <KeyValuePair
                    className="pt-2"
                    label="Bank Name"
                    value={bankAccountInfo.bank_name ?? "NA"}
                  />
                )}
              </div>

              <div
                className={`col-md-6 ${
                  toggleUpdateKYCDetails ? "py-2" : "py-4"
                }`}
              >
                {toggleUpdateKYCDetails ? (
                  <div className={Styles.editKycDocumentWrapper}>
                    <Input
                      type="text"
                      name="ifsc_code"
                      id="ifsc_code"
                      autoComplete="off"
                      hasError={error.ifsc_code}
                      isValid={validData.ifsc_code}
                      placeholder="Enter IFSC Code"
                      labelname="IFSC Code"
                      maxLength={11}
                      value={tempBankAccountInfo.ifsc_code}
                      no_shadow="true"
                      onChange={(event: any) => {
                        setTempBankAccountInfo((bankAccountInfo: any) => ({
                          ...bankAccountInfo,
                          ifsc_code: event.target.value,
                        }));
                        setError((error) => ({
                          ...error,
                          ifsc_code: "",
                        }));
                        setValidData((validData) => ({
                          ...validData,
                          ifsc_code: true,
                        }));
                      }}
                      onBlur={checkIfsc}
                    />
                    {error.ifsc_code && (
                      <ErrorMessage>{error.ifsc_code}</ErrorMessage>
                    )}
                  </div>
                ) : (
                  <KeyValuePair
                    className="pt-2"
                    label="IFSC Code"
                    value={bankAccountInfo.ifsc_code ?? "NA"}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {toggleApproveAccountButton &&
        onboardingStatus === "ACTIVATION_REQUESTED" && (
          <ApproveKycDetailsModal
            message={
              "Please approve the KYC details of the organisation to activate their account and create a virtual account."
            }
            org_id={org_id}
            onClosePopUp={() => setToggleApproveAccountButton(false)}
            onApprove={(virtualAccount: any) => {
              dispatch({
                type: "ADD_TOAST",
                payload: {
                  id: Math.floor(Math.random() * 100),
                  type: SUCCESS,
                  message:
                    "Account has been approved and virtual account has been created",
                },
              });
            }}
          />
        )}
    </div>
  );
}

export default NationalCustomer;

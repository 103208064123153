import React, { useContext } from "react";
import Styles from "../products.module.scss";
import sharedStyle from "../../../../../../shared/sharedStyle.module.scss";
import Button from "../../../../../../shared/button/button";
import Loading from "../../../../../../shared/loading/loading";
import { useParams } from "react-router-dom";
import { ACCENTCOLOR } from "../../../../../../shared/colors";
import { callPutApi } from "../../../../../../api/axios";
import { ToastContext } from "../../../../../../context/toastContext";
import { ERROR, SUCCESS } from "../../../../../../utils/toastType";
import { COMPRESSED } from "../../../../../../shared/buttonSize";
import {
  successButtonStyle,
  successButtonHoverStyle,
} from "../../../../../../shared/buttonStyles";
import { ApmContext } from "../../../../../../utils/apmProvider";

function UnsubscribedProducts({
  unsubscribedProduct,
  isLoading,
  getUnsubscribedProducts,
  getActiveProducts,
  getPendingProducts,
  is_allowed_to_write,
}: any) {
  const { id }: any = useParams();
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [subscribeButtonLoading, setSubscribeButtonLoading] = React.useState();
  const dispatch = useContext(ToastContext);
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  const loading = (
    <div
      style={{ height: "250px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Loading loadingColor={ACCENTCOLOR} />
    </div>
  );
  const emptyState = (
    <div
      style={{ height: "250px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <h5 className="mb-0">No Products Found</h5>
    </div>
  );

  async function onSubscribeProduct(product: any) {
    setSubscribeButtonLoading(product.id);
    setButtonLoading(true);
    try {
      const { data }: any = await callPutApi(
        `/organisations/${id}?action=subscribe_products`,
        { product_ids: [product.id] }
      );
      setButtonLoading(false);
      getUnsubscribedProducts();
      getActiveProducts();
      getPendingProducts();
      if (data.length > 0) {
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: SUCCESS,
            message: "Product Subscribed Succesfully",
          },
        });
      }
    } catch (err: any) {
      setButtonLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message:
            err.response.data.err + " TraceId is \n" + apmTraceId(apmInstance),
          copy: apmTraceId(apmInstance),
        },
      });
    }
  }
  return (
    <div className={sharedStyle.card}>
      <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
        <div className="d-flex align-items-center">
          <p className={`mb-0 ${sharedStyle.cardHeader}`}>
            Unsubscribed Products
          </p>
        </div>
      </div>
      {isLoading ? (
        loading
      ) : (
        <div style={{ overflowX: "auto" }}>
          {unsubscribedProduct.length > 0 ? (
            <table style={{ width: "100%" }}>
              <thead className={sharedStyle.tableHeaderBack}>
                <tr>
                  <th
                    className={Styles.product_name_column}
                    style={{ paddingLeft: "25px" }}
                  >
                    <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                      Product Name
                    </p>
                  </th>
                  <th className={Styles.status}>
                    {is_allowed_to_write ? (
                      <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                        <span style={{ paddingLeft: "150px" }}>Action</span>
                      </p>
                    ) : null}
                  </th>
                </tr>
              </thead>
              <tbody className={Styles.tableWrapper}>
                {unsubscribedProduct.map((product: any, index: number) => {
                  return (
                    <tr
                      key={index}
                      style={
                        index !== unsubscribedProduct.length - 1
                          ? { borderBottom: "1px solid #eceeef" }
                          : { borderBottom: 0 }
                      }
                    >
                      <td
                        className={Styles.product_name_column}
                        style={{ paddingLeft: "25px" }}
                      >
                        <p className={`mb-0 ${Styles.productName}`}>
                          {product.name}
                        </p>
                      </td>
                      <td className={Styles.status}>
                        <div style={{ width: "100px", paddingLeft: "122px" }}>
                          {is_allowed_to_write ? (
                            <Button
                              size={COMPRESSED}
                              style={successButtonStyle}
                              hoveredStyle={successButtonHoverStyle}
                              id={index}
                              onClick={() => onSubscribeProduct(product)}
                              isLoading={
                                product.id === subscribeButtonLoading
                                  ? buttonLoading
                                  : false
                              }
                              disabled={buttonLoading}
                            >
                              Subscribe
                            </Button>
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            emptyState
          )}
        </div>
      )}
    </div>
  );
}

export default UnsubscribedProducts;
